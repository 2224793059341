export class OptimizationRunMoveDetails {
  reason: string;
  itemId: string;
  consignee: string;
  sku: string;
  locationIdFrom?: string;
  locationIdTo?: string;
  retryCount: number;
}

export class OptimizationRunLogRecordDetails {
  moves?: OptimizationRunMoveDetails[];
  proposedCostVector: number[];
  costChangeVector: number[];
}
