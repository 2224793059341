import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import * as Icon from '../../components/icons';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import useUserPermissionFeatures from '../../permissions/roles/hooks/useUserPermissionFeatures';
import { visibleFeaturesKeys } from '../../permissions/store/role.state';
import { FeatureKey } from '../../permissions/types';

// New atom for managing editable feature keys
const editableFeatureKeysState = atom<FeatureKey[]>({
  key: 'editableFeatureKeysState',
  default: [],
});

const FeedFeaturesPermissionManagementComponent: React.FC = () => {
  const { t } = useTranslation('app');
  const originalFeatureKeys = useRecoilValue(visibleFeaturesKeys);
  const [editableFeatureKeys, setEditableFeatureKeys] = useRecoilState(
    editableFeatureKeysState,
  );
  const features = useUserPermissionFeatures();

  // Initialize editable keys with original keys
  useEffect(() => {
    setEditableFeatureKeys(originalFeatureKeys);
  }, [originalFeatureKeys, setEditableFeatureKeys]);

  const handleToggle = (id: string) => {
    setEditableFeatureKeys(currentKeys => {
      const newKeys = currentKeys.filter(key => {
        if (key.startsWith('!')) {
          return key.slice(1) !== id;
        }
        return key !== id;
      });

      const isCurrentlyVisible = currentKeys.includes(id as FeatureKey);
      if (isCurrentlyVisible) {
        newKeys.push(`!${id}` as FeatureKey);
      } else {
        newKeys.push(id as FeatureKey);
      }

      return newKeys;
    });
  };

  // Function to determine if a feature is visible based on editableFeatureKeys
  const isFeatureVisible = (id: string) => {
    return (
      editableFeatureKeys.includes(id as FeatureKey) &&
      !editableFeatureKeys.includes(`!${id}` as FeatureKey)
    );
  };

  return (
    <>
      <ScreenTitle
        title={t('Feature Visibility', { ns: 'features' })}
        subtitle={t('Feed', { ns: 'features' })}
        isSticky
        // helpNavTo={'feed/feature-permissions'}
        icon={Icon.ObjectSettings}
      />
      <ul
        data-component="FeedFeaturesPermissionManagementComponent"
        className={classNames('my-4 space-y-6 p-4')}
      >
        {features.map(({ id, title }) => (
          <div className={classNames('flex')} key={id}>
            <label className={classNames('flex-1 opacity-80')}>{title}</label>
            <ButtonSwitchMulti
              autoSize
              options={[
                { label: t('off', { ns: 'features' }) },
                { label: t('on', { ns: 'features' }) },
              ]}
              selectedIndex={isFeatureVisible(id) ? 1 : 0}
              onClick={() => handleToggle(id)}
            />
          </div>
        ))}
      </ul>
    </>
  );
};

export default FeedFeaturesPermissionManagementComponent;
