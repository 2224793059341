import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import InboxZero from '../../components/InboxZero';
import { Button } from '../../components/actions/Button';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import PanelContainer from '../../containers/PanelContainer';
import { actualityResourcePolicy } from '../store/actuality.state';
import { feedDisabledAgentTypes } from '../store/feed.state';

const ResourceFilters: React.FC = () => {
  const { t } = useTranslation('feed');
  const [disabledAgentTypes, setDisabledAgentTypes] = useRecoilState(
    feedDisabledAgentTypes,
  );

  const policy = useRecoilValue(actualityResourcePolicy);

  const [disabledAgentTypesLocal, setDisabledAgentTypesLocal] =
    useState<string[]>();

  const disabledAgentsSet = new Set(disabledAgentTypesLocal);

  useEffect(() => {
    setDisabledAgentTypesLocal(disabledAgentTypes);
  }, [disabledAgentTypes]);

  function toggleAgentType(id: string, isSelect: boolean) {
    const others = _.filter(disabledAgentTypesLocal, at => at !== id);
    if (!isSelect) {
      others.push(id);
    }
    setDisabledAgentTypesLocal(others);
  }

  return (
    <PanelContainer
      id="panel-feed-global-filters-agent-type"
      title={t`Resource filters`}
      collapsible
      hasPadding
    >
      {_.isEmpty(policy?.agents) && (
        <InboxZero message={t`No Resources found`} />
      )}
      <PanelContainer
        id="panel-feed-global-filters-agent-type"
        title={t`Agent types filters`}
        collapsible
        hasPadding
      >
        {_.isEmpty(policy?.agents) && (
          <InboxZero message={t`No Agent fount in resource policy`} />
        )}
        <ul className={classNames('mb-6 space-y-6')}>
          {_.map(policy?.agents, agent => {
            const isDisabled =
              disabledAgentsSet.size !== 0 && disabledAgentsSet.has(agent.id);
            return (
              <div
                className={classNames('flex')}
                key={`agent-global-filter-${agent.id}`}
              >
                <label className={classNames('flex-1 opacity-80')}>
                  {agent.title}
                </label>
                <ButtonSwitchMulti
                  autoSize
                  options={[{ label: t('off') }, { label: t('on') }]}
                  selectedIndex={isDisabled ? 0 : 1}
                  onClick={v => toggleAgentType(agent.id, v === 1)}
                />
              </div>
            );
          })}
        </ul>
      </PanelContainer>
      <ActionBar sticky stickyTop>
        <Spacer flexspace />
        <Button
          label={t`Apply filter`}
          buttonType="primary"
          buttonSize="xs"
          className="!py-2"
          onPress={() => setDisabledAgentTypes(disabledAgentTypesLocal)}
        />
      </ActionBar>
    </PanelContainer>
  );
};

export default ResourceFilters;
