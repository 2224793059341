import {
  AllocationSummaryFragment,
  AllocationSummaryKeyFragment,
  AllocationSummaryRequirementDetailsFragment,
  LoadAllocationSummaryQuery,
  LoadAllocationSummaryQueryVariables,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { getDataRows, getHeaderRow } from './export-converter.helper';
import { ColumnConfigBase, ExportConverter, ExportJobParams } from './types';

export type AllocationSummaryExportJobParams = ExportJobParams<
  LoadAllocationSummaryQueryVariables,
  AllocationSummaryConverterConfig
>;

export const allocationSummaryExportJobParams: AllocationSummaryExportJobParams =
  {
    converterId: 'ALLOCATION_SUMMARY',
    query: null,
    config: null,
    variables: null,
    filename: 'allocation-summary.csv',
  };
export type AllocationSummaryDetails = Omit<
  AllocationSummaryRequirementDetailsFragment,
  '__typename'
>;

export type AllocationSummaryDataRow = AllocationSummaryKeyFragment & {
  key: AllocationSummaryKeyFragment;
  requiredQty: number;
  requiredUOM: Record<string, number>;
  requiredUOMString: string;

  initiallyPickableQty: number;
  initiallyPickableUOM: Record<string, number>;
  initiallyPickableUOMString: string;
  initiallyPickableLocationCount: number;

  toAllocateRequiredQty: number;
  toAllocateRequiredUOM: Record<string, number>;
  toAllocateRequiredUOMString: string;

  toReallocateQty: number;
  toReallocateUOM: Record<string, number>;
  toReallocateUOMString: string;

  toAllocateQty: number;
  toAllocateUOM: Record<string, number>;
  toAllocateUOMString: string;

  unpickableQty: number;
  unpickableUOM: Record<string, number>;
  unpickableUOMString: string;

  allocatedQty: number;
  allocatedUOM: Record<string, number>;
  allocatedUOMString: string;
  allocatedLocationCount: number;

  allocatedSharedQty: number;
  allocatedSharedUOM: Record<string, number>;
  allocatedSharedUOMString: string;
  allocatedSharedLocationCount: number;

  unAllocatedQty: number;
  unAllocatedUOM: Record<string, number>;
  unAllocatedUOMString: string;
  unAllocatedReasons: string[];
  unAllocatedReasonsString: string;

  deallocatedQty: number;
  deallocatedUOM: Record<string, number>;
  deallocatedUOMString: string;
  deallocatedReasons: string[];
  deallocatedReasonsString: string;
  deallocatedLocationCount: number;

  // allocatedTotalQty: number;
  // allocatedTotalUOM: string;
};

export type AllocationSummaryDataColumn = keyof AllocationSummaryDataRow;

export type AllocationSummaryConverterConfig = {
  columnsConfig: ColumnConfigBase<AllocationSummaryDataRow>[];
  //   dictionaries: {
  //     rules: Record<string, string>;
  //   };
};

function getReducedUoms(
  details: AllocationSummaryDetails,
): Record<string, number> {
  return _.reduce(
    details.uoms,
    (acc, uom) => {
      const key = uom.uom;
      return {
        ...acc,
        [key]: uom.uomQuantity,
      };
    },
    {} as Record<string, number>,
  );
}

function getConcatenatedUoms(details: AllocationSummaryDetails): string {
  return _.reduce(
    details.uoms,
    (acc, uom) => `${acc} ${uom.uom}: ${uom.uomQuantity}`,
    '',
  );
}

export const getAllocationSummaryTableRows = (
  data: AllocationSummaryFragment[],
  config: AllocationSummaryConverterConfig,
): AllocationSummaryDataRow[] => {
  const reduceAllocationSummaryItem = (
    summary: AllocationSummaryFragment,
  ): AllocationSummaryDataRow => {
    return {
      ...summary.key,
      key: summary.key,
      requiredQty: summary.required.totalQuantity,
      requiredUOM: getReducedUoms(summary.required),
      requiredUOMString: getConcatenatedUoms(summary.required),

      initiallyPickableQty: summary.initiallyPickable.totalQuantity,
      initiallyPickableUOM: getReducedUoms(summary.initiallyPickable),
      initiallyPickableUOMString: getConcatenatedUoms(
        summary.initiallyPickable,
      ),
      initiallyPickableLocationCount:
        summary.initiallyPickable.totalLocationCount,

      toAllocateRequiredQty: summary.toAllocateRequired.totalQuantity,
      toAllocateRequiredUOM: getReducedUoms(summary.toAllocateRequired),
      toAllocateRequiredUOMString: getConcatenatedUoms(
        summary.toAllocateRequired,
      ),

      toReallocateQty: summary.toReallocate.totalQuantity,
      toReallocateUOM: getReducedUoms(summary.toReallocate),
      toReallocateUOMString: getConcatenatedUoms(summary.toReallocate),

      toAllocateQty: summary.toAllocate.totalQuantity,
      toAllocateUOM: getReducedUoms(summary.toAllocate),
      toAllocateUOMString: getConcatenatedUoms(summary.toAllocate),

      unpickableQty: summary.unpickable.totalQuantity,
      unpickableUOM: getReducedUoms(summary.initiallyPickable),
      unpickableUOMString: getConcatenatedUoms(summary.initiallyPickable),

      allocatedQty: summary.allocated.totalQuantity,
      allocatedUOM: getReducedUoms(summary.allocated),
      allocatedUOMString: getConcatenatedUoms(summary.allocated),
      allocatedLocationCount: summary.allocated.totalLocationCount,

      allocatedSharedQty: summary.allocatedShared.totalQuantity,
      allocatedSharedUOM: getReducedUoms(summary.allocatedShared),
      allocatedSharedUOMString: getConcatenatedUoms(summary.allocatedShared),
      allocatedSharedLocationCount: summary.allocatedShared.totalLocationCount,

      unAllocatedQty: summary.unallocated.totalQuantity,
      unAllocatedUOM: getReducedUoms(summary.unallocated),
      unAllocatedUOMString: getConcatenatedUoms(summary.unallocated),

      unAllocatedReasons: summary.unallocated?.reasons,
      unAllocatedReasonsString: _.join(summary.unallocated?.reasons, ', '),

      deallocatedQty: summary.deallocated.totalQuantity,
      deallocatedUOM: getReducedUoms(summary.deallocated),
      deallocatedUOMString: getConcatenatedUoms(summary.deallocated),
      deallocatedReasons: summary.deallocated?.reasons,
      deallocatedReasonsString: _.join(summary.deallocated?.reasons, ', '),
      deallocatedLocationCount: summary.deallocated?.totalLocationCount,
      // allocatedTotalQty: number;
      // allocatedTotalUOM: string;
    };
  };

  return _.map(data, reduceAllocationSummaryItem);
};

export const ALLOCATION_SUMMARY_EXPORT_CONVERTER: ExportConverter<
  LoadAllocationSummaryQuery,
  AllocationSummaryConverterConfig
> = (input, config) => {
  const data = getAllocationSummaryTableRows(
    input.simulation.latestAllocationRun?.resultRequirementsSummary,
    config,
  );
  return [
    getHeaderRow(config.columnsConfig),
    getDataRows(data, config.columnsConfig),
  ];
};
