import {
  AnalyzeResultStatus,
  OptimizationRunStatus,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { assignmentItemsDataState } from '../../assignment/store/assignment.state';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { itemSetItemsDataState } from '../../itemSet/store/itemSet.state';
import { orderSetItemsDataState } from '../../orders/store/orderSet.state';
import { analyzeLocationProductsTableState } from '../store/analyze.state';
import { optimisationStatus } from '../store/optimisation.state';
import {
  simulationAnalyzeStatus,
  simulationShowDatasetAsTable,
} from '../store/simulation.state';
import { DataSetTableType } from '../store/simulation.types';

type ItemTagProps = {
  title: string;
  filters: Record<string, string>;
  color?: string;
};

const ItemTag: React.FC<ItemTagProps> = props => {
  const { t } = useTranslation('simulation');
  const hasAnalyze =
    useRecoilValue(simulationAnalyzeStatus) === AnalyzeResultStatus.COMPLETED;
  const hasOptimise =
    useRecoilValue(optimisationStatus) === OptimizationRunStatus.COMPLETED;

  const [showTable, setShowTable] = useRecoilState(
    simulationShowDatasetAsTable,
  );
  const [assignmentTableState, setAssignmentTableState] = useRecoilState(
    assignmentItemsDataState,
  );
  const [orderSetTableState, setOrderSetTableState] = useRecoilState(
    orderSetItemsDataState,
  );
  const [itemSetTableState, setItemSetTableState] = useRecoilState(
    itemSetItemsDataState,
  );
  const [analyzedProductsTableState, setAnalyzedProductsTableState] =
    useRecoilState(analyzeLocationProductsTableState);

  const isSelectedAssignment =
    showTable === 'assignment' &&
    _.isEqual(assignmentTableState?.searchValues, props.filters);

  const isSelectedOrderSet =
    showTable === 'order-set' &&
    _.isEqual(orderSetTableState?.searchValues, props.filters);

  const isSelectedItemSetSet =
    showTable === 'item-set' &&
    _.isEqual(itemSetTableState?.searchValues, props.filters);

  const isSelectedOptimisedAssignment =
    showTable === 'assignment-optimised' &&
    _.isEqual(assignmentTableState?.searchValues, props.filters);

  const isSelectedAnalyzedProducts =
    showTable === 'analyzed-location-products' &&
    _.isEqual(analyzedProductsTableState?.searchValues, props.filters);
  const isSelectedOptimizedProducts =
    showTable === 'optimized-location-products' &&
    _.isEqual(analyzedProductsTableState?.searchValues, props.filters);

  function showAssignmentTable(show: boolean) {
    setShowTable(show ? 'assignment' : null);
    setAssignmentTableState({
      totalCount: 0,
      searchValues: show ? props.filters : null,
    });
  }

  function showOptimisedAssignmentTable(show: boolean) {
    setShowTable(show ? 'assignment-optimised' : null);
    setAssignmentTableState({
      totalCount: 0,
      searchValues: show ? props.filters : null,
    });
  }

  function showOrderSetTable(show: boolean) {
    setShowTable(show ? 'order-set' : null);
    setOrderSetTableState({
      totalCount: 0,
      searchValues: show ? props.filters : null,
    });
  }

  function showItemSetTable(show: boolean) {
    setShowTable(show ? 'item-set' : null);
    setItemSetTableState({
      searchValues: show ? props.filters : null,
      sortValues: { sku: SortDirection.ASC },
    });
  }

  function showAnalyzedProductsTable(show: boolean) {
    setShowTable(show ? 'analyzed-location-products' : null);
    setAnalyzedProductsTableState({
      sortValues: {},
      searchValues: show ? props.filters : null,
    });
  }

  function showOptimizedProductsTable(show: boolean) {
    setShowTable(show ? 'optimized-location-products' : null);
    setAnalyzedProductsTableState({
      sortValues: {},
      searchValues: show ? props.filters : null,
    });
  }

  const options: Partial<
    Record<DataSetTableType, { title: string; onClick: () => void }>
  > = {
    assignment: {
      title: t`Show Assignment`,
      onClick: () => showAssignmentTable(!isSelectedAssignment),
    },
    'order-set': {
      title: t`Show Orders`,
      onClick: () => showOrderSetTable(!isSelectedOrderSet),
    },
    'item-set': {
      title: t`Show Items`,
      onClick: () => showItemSetTable(!isSelectedItemSetSet),
    },
  };
  if (hasAnalyze) {
    options['analyzed-location-products'] = {
      title: t`Show Analyzed Products`,
      onClick: () => showAnalyzedProductsTable(!isSelectedAnalyzedProducts),
    };
  }
  if (hasOptimise) {
    options['assignment-optimised'] = {
      title: t`Show Optimised Assignment`,
      onClick: () =>
        showOptimisedAssignmentTable(!isSelectedOptimisedAssignment),
    };
    options['optimized-location-products'] = {
      title: t`Show Optimized Products`,
      onClick: () => showOptimizedProductsTable(!isSelectedOptimizedProducts),
    };
  }
  return (
    <div data-component="ItemTag" className="flex-1 ltr:mr-2 rtl:ml-2">
      <div
        aria-label={t`Item Value`}
        className={classNames(
          'whitespace-nowrap',
          'group-hover:text-menu-active cursor-pointer',
          'flex items-center',
        )}
      >
        {!_.isNil(props.color) && (
          <div
            className={classNames('flex h-6 w-1 rounded-sm px-1 py-1')}
            style={{
              backgroundColor: props.color,
            }}
          />
        )}

        <DropdownSelector
          className="text-xxs"
          classNameLabel="text-minimal"
          classNameItem="text-xxs"
          onClick={e => {
            e.stopPropagation();
          }}
          DropAlignLeft
          buttonTransparent
          vertical
          panelMode
          value={'...'}
          values={_.keys(options)}
          renderValue={v => options[v].title}
          onChange={async (v, e) => {
            e.stopPropagation();
            options[v].onClick();
          }}
        />

        <span className={classNames('flex-1 truncate ltr:ml-1 rtl:mr-1')}>
          {props.title}
        </span>
      </div>
    </div>
  );
};

export default ItemTag;
