import { SortDirection } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import {
  HeatmapBucket,
  HeatmapBucketSortDirection,
  HeatmapBucketStatField,
} from '../../common/heatmap.helper';
import { HeatmapMetricRange } from '../../common/heatmap.types';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import {
  viewerSelectedBay,
  viewerSelectedLevel,
} from '../../layout/viewer/store/viewer.state';
import { actualityMetricDescriptorsMap } from '../../metrics/actuality/actualityMetric.default';
import { getActualityHeatmapBuckets } from '../../metrics/actuality/actualityMetric.helper';
import {
  ActualityMetric,
  ActualityMetricDescriptorBase,
} from '../../metrics/actuality/actualityMetric.types';
import { getProductCategory } from '../../simulation/store/abc/simulation.ABC.helper';
import { HeatmapFilter } from '../../simulation/store/simulation.types';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { getActualityHeatmapMetricRange } from './actuality.heatmap.helper';
import {
  actualityMainViewType,
  actualitySelectedDocument,
} from './actuality.state';
import {
  ActualityHeatmapDataColumn,
  ActualityHeatmapDataQueryParams,
  ActualityHeatmapDataRow,
  getActualityHeatmapDataQuery,
} from './datasetQueries/actualityHeatmapData';
import {
  ActualityMetricSummaryRow,
  getActualityMetricSummary,
} from './datasetQueries/actualityHq';
import { executeDatasetQuery } from './feed.helper';
import { feedItemsWithRankMap, feedQueryBuilderParams } from './feed.state';

const getKey = (postfix: string) => `warebee-actuality-heatmap-${postfix}`;

export const actualityHeatmapBuilderParams =
  selector<ActualityHeatmapDataQueryParams>({
    key: getKey('heatmap-query-builder-params'),
    get: ({ get }) => {
      const actuality = get(actualitySelectedDocument);
      const queryParamsBase = get(feedQueryBuilderParams);
      const metric = get(actualityMetricDescriptor);
      return {
        ...queryParamsBase,
        itemSetId: actuality.itemSetId,
        layoutId: actuality.layoutId,
        groupBy: metric.path as any, // todo : correct type
        aggregationFn: metric.aggregationFn ?? 'min',
        filterBy: metric.filterBy,
      };
    },
  });

export const actualityMetricSelected = atom<ActualityMetric>({
  key: getKey('metric-selected'),
  default: 'visits',
});

export const actualityMetricDescriptor =
  selector<ActualityMetricDescriptorBase>({
    key: getKey('metric-descriptor'),
    get: ({ get }) => {
      const metric = get(actualityMetricSelected);
      return actualityMetricDescriptorsMap[metric];
    },
  });

export const actualityMetricHasStats = selector<boolean>({
  key: getKey('metric-has-stats'),
  get: ({ get }) => {
    const metricDescriptor = get(actualityMetricDescriptor);
    return (
      metricDescriptor.rangeType === 'distinct' &&
      metricDescriptor.type !== 'abc'
    );
  },
});

export const actualityHeatmapFilters = atom<HeatmapFilter>({
  key: getKey('heatmap-filters'),
  default: {
    hiddenBuckets: {},
  },
});

export const actualityHeatmapSummary = selector<ActualityMetricSummaryRow[]>({
  key: getKey('summary'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const actuality = get(actualitySelectedDocument);
    const metric = get(actualityMetricDescriptor);

    if (_.isNil(actuality.itemSetId) || _.isNil(actuality.layoutId)) {
      return null;
    }

    const queryParamsBase = get(feedQueryBuilderParams);

    const compiledQuery = getActualityMetricSummary({
      ...queryParamsBase,
      itemSetId: actuality.itemSetId,
      layoutId: actuality.layoutId,
      aggregationFn: metric.aggregationFn,
      groupBy: metric.path as any, // todo : correct type,
      aggregateBy: (metric.aggregateBy ?? metric.path) as any,
      filterBy: metric.filterBy,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Heatmap Summary',
    });

    return result as ActualityMetricSummaryRow[];
  },
});

export const actualityHeatmapRangeDescriptor = selector<HeatmapMetricRange>({
  key: getKey('heatmap-range-descriptor'),
  get: ({ get }) => {
    const metricDescriptor = get(actualityMetricDescriptor);
    const heatmapSummary = get(actualityHeatmapSummary);
    return getActualityHeatmapMetricRange(metricDescriptor, heatmapSummary);
  },
});

const actualityHeatmapBucketsSortByAtom = persistAtom<HeatmapBucketStatField>({
  key: getKey('heatmap-buckets-sort-by-atom'),
  default: null,
});

export const actualityHeatmapBucketsSortBy = selector<HeatmapBucketStatField>({
  key: getKey('heatmap-buckets-sort-by'),
  get: ({ get }) => {
    const hasStats = get(actualityMetricHasStats);
    if (!hasStats) {
      return null;
    }
    return get(actualityHeatmapBucketsSortByAtom);
  },

  set: ({ set }, value) => set(actualityHeatmapBucketsSortByAtom, value),
});

export const actualityHeatmapBucketsSortDirection =
  persistAtom<HeatmapBucketSortDirection>({
    key: getKey('heatmap-buckets-sort-direction'),
    default: 'desc',
  });

const actualityHeatmapBucketsInner = selector<HeatmapBucket[]>({
  key: getKey('heatmap-buckets-inner'),
  get: ({ get }) => {
    // const namedColors = get(namedColorsGroupSelected);
    const metricDescriptor = get(actualityMetricDescriptor);
    const rangeDescriptor = get(actualityHeatmapRangeDescriptor);
    if (_.isNil(rangeDescriptor)) return null;

    return getActualityHeatmapBuckets(rangeDescriptor, metricDescriptor);
  },
});

export const actualityHeatmapBuckets = selector<HeatmapBucket[]>({
  key: getKey('heatmap-buckets'),
  get: ({ get }) => {
    const buckets = get(actualityHeatmapBucketsInner);
    const sortBy = get(actualityHeatmapBucketsSortBy);
    const sortDirection = get(actualityHeatmapBucketsSortDirection);

    return _.orderBy(
      buckets,
      b => {
        if (sortBy && b.stats) {
          return b.stats?.[sortBy];
        }
        return b.index;
      },
      sortDirection,
    );
  },
});

export const actualityHeatmapLevelData = selector<ActualityHeatmapDataRow[]>({
  key: getKey('actuality-heatmap-by-level'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const actuality = get(actualitySelectedDocument);
    const metric = get(actualityMetricDescriptor);
    const level = get(viewerSelectedLevel);

    if (_.isNil(actuality.itemSetId) || _.isNil(actuality.layoutId)) {
      return null;
    }

    const queryParamsBase = get(feedQueryBuilderParams);

    const compiledQuery = getActualityHeatmapDataQuery({
      ...queryParamsBase,
      itemSetId: actuality.itemSetId,
      layoutId: actuality.layoutId,
      groupBy: metric.path as any, // todo : correct type
      aggregationFn: metric.aggregationFn ?? 'min',
      locationLevel: level,
      filterBy: metric.filterBy,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Heatmap By Level',
    });

    if (metric.type === 'abc') {
      const itemsWithRank = get(feedItemsWithRankMap);
      _.forEach(result, row => {
        row.dimensionValue = itemsWithRank[row.skuKey]
          ? getProductCategory(itemsWithRank[row.skuKey].cmlPercentRank)?.key
          : null;
      });
    }

    return result as ActualityHeatmapDataRow[];
  },
});

export const actualityHeatmapBayData = selector<ActualityHeatmapDataRow[]>({
  key: getKey('actuality-heatmap-by-bay'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const actuality = get(actualitySelectedDocument);
    const metric = get(actualityMetricDescriptor);
    const bay = get(viewerSelectedBay);

    if (
      _.isNil(actuality.itemSetId) ||
      _.isNil(actuality.layoutId) ||
      _.isNil(bay)
    ) {
      return null;
    }

    const queryParamsBase = get(feedQueryBuilderParams);

    const compiledQuery = getActualityHeatmapDataQuery({
      ...queryParamsBase,
      itemSetId: actuality.itemSetId,
      layoutId: actuality.layoutId,
      groupBy: metric.path as any, // todo : correct type
      aggregationFn: metric.aggregationFn ?? 'min',
      bayId: bay.id,
      filterBy: metric.filterBy,
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[actuality] Heatmap By Bay',
    });

    if (metric.type === 'abc') {
      const itemsWithRank = get(feedItemsWithRankMap);
      _.forEach(result, row => {
        row.dimensionValue = itemsWithRank[row.skuKey]
          ? getProductCategory(itemsWithRank[row.skuKey].cmlPercentRank)?.key
          : null;
      });
    }

    return result as ActualityHeatmapDataRow[];
  },
});

export const actualityHeatmapLocationByLevel = selector<Record<number, number>>(
  {
    key: getKey('location-by-level'),
    get: ({ get }) => {
      const buckets = get(actualityHeatmapBuckets);
      const bucketsFilter = get(actualityHeatmapFilters);
      const affectedBuckets = _.filter(
        buckets,
        b => !bucketsFilter.hiddenBuckets[b.id],
      );

      return _(affectedBuckets)
        .map(b => _.entries(b.levelStats))
        .flatten()
        .reduce((acc, [level, levelStat]) => {
          return {
            ...acc,
            [level]: levelStat.totalExistedLocations + (acc[level] ?? 0),
          };
        }, {});
    },
  },
);

export const actualityHeatmapShowTable = selector<boolean>({
  key: getKey('show-heatmap-table'),
  get: ({ get }) => {
    return get(actualityMainViewType) === 'table';
  },
});

export const actualityHeatmapTableData = atom<ActualityHeatmapDataRow[]>({
  key: getKey('heatmap-table-data'),
  default: [],
});

export const actualityHeatmapTableTotalCount = atom<number>({
  key: getKey('heatmap-table-total-count'),
  default: 0,
});

export const actualityHeatmapTableDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('heatmap-table-data-status'),
  default: AsyncLoadStatus.None,
});

export const actualityHeatmapTableDataState = atom<
  DataTableState<ActualityHeatmapDataColumn>
>({
  key: getKey('heatmap-table-data-state'),
  default: {
    sortValues: {
      dimensionValue: SortDirection.DESC,
    },
    searchValues: {},
  },
});
