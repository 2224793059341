import { ExportJobFragment } from '@warebee/frontend/data-access-api-graphql';
import {
  getLocationsStatsTableRows,
  LocationsStatsConverterConfig,
  LocationsStatsDataColumn,
  LocationsStatsDataRow,
} from '@warebee/shared/export-converter';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formatInteger } from '../../common/formatHelper';
import { AsyncLoadStatus, DataPageParams } from '../../common/types';
import useFormatter from '../../common/useFormatter';
import DropdownSelector from '../../components/actions/DropdownSelector';
import DatasetTable, { ColumnConfig } from '../../components/DatasetTable';
import { Container } from '../../components/layout/ContainerFlex';
import { Stat } from '../../components/stats/Stat';
import { StatGroup } from '../../components/stats/StatGroup';
import { viewerAislesMeta } from '../../layout/viewer/store/viewer.state';
import { warehouseMeasurementSystem } from '../../store/warehouse.state';
import { UomSpec } from '../data/palletSpecsHelper';
import {
  policyFilteredLocationStats as policyFilteredLocationsStats,
  policyFilteredLocationsStatsLoadStatus,
  policyFilteredLocationsStatsSearchValues,
  policyLocationsStatsDimension,
} from '../store/policyFilter.state';
import {
  getLocationsStatsDimensions,
  getLocationsStatsTableConfigDefault,
  locationStatsDrillDownDimensions,
  locationStatsFixedDimensions,
  useLocationCapacitySpec,
} from './locationsStatsTable.config';

export type LocationsStatsByRuleTableViewProps = {
  id: string;
  title: string;
  onChange: (page?: DataPageParams) => void;
  columnsConfig?: ColumnConfig<LocationsStatsDataRow>[];
  keyFields?: LocationsStatsDataColumn[];
  startExportCSV: () => Promise<{ job: ExportJobFragment; errors: any }>;
};

const LocationsStatsByRuleTableView: React.FC<
  LocationsStatsByRuleTableViewProps
> = props => {
  const { t } = useTranslation('simulation');
  const ms = useRecoilValue(warehouseMeasurementSystem);
  const aislesMap = useRecoilValue(viewerAislesMeta);
  const [locationsStatsData, setLocationsStatsData] = useRecoilState(
    policyFilteredLocationsStats,
  );
  const [dimensionId, setDimensionId] = useRecoilState(
    policyLocationsStatsDimension,
  );
  const status = useRecoilValue(policyFilteredLocationsStatsLoadStatus);
  const [searchValues, setSearchValues] = useRecoilState(
    policyFilteredLocationsStatsSearchValues,
  );
  const formatter = useFormatter();

  const [palletSpecs, setPalletSpecs] = useState<UomSpec[]>([]);
  const [selectedPalletSpec, setSelectedPalletSpec] = useState<UomSpec | null>(
    null,
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [customPalletSize, setCustomPalletSize] = useState({
    width: 0,
    length: 0,
    height: 0,
  });

  const locationCapacitySpec = useLocationCapacitySpec();
  const dimensions = getLocationsStatsDimensions(t);
  const defaultColumnConfig = getLocationsStatsTableConfigDefault(
    dimensionId,
    t,
    ms,
  );

  useEffect(() => {
    if (locationCapacitySpec.length > 0 && !selectedPalletSpec) {
      setSelectedPalletSpec(locationCapacitySpec[0]);
    }
  }, [t, selectedPalletSpec]);

  const defaultKeyFields = _.map(
    [dimensionId, ...locationStatsFixedDimensions],
    d => dimensions[d].field,
  );

  const { columnsConfig = defaultColumnConfig, keyFields = defaultKeyFields } =
    props;

  const effectiveColumnsSet: Set<string> = new Set(
    _.map(columnsConfig, c => c.field),
  );
  const effectiveSearchValues = _.pickBy(searchValues, (value, key) =>
    effectiveColumnsSet.has(key),
  );

  const isLoading = status === AsyncLoadStatus.Loading;
  const itemsCount = locationsStatsData?.length || 0;
  const totalCount = locationsStatsData?.length ?? 0;

  const sortBy = dimensions[dimensionId]?.field;

  const converterConfig: LocationsStatsConverterConfig = {
    columnsConfig,
    dictionaries: {
      aisleTitleMap: aislesMap,
    },
    searchValues: effectiveSearchValues,
    sortColumnField: sortBy,
  };

  const data = getLocationsStatsTableRows(locationsStatsData, converterConfig);

  const getTotalBy = (
    column: LocationsStatsDataColumn,
    filter: Partial<Record<LocationsStatsDataColumn, any>>,
  ) => {
    return _(data)
      .filter(row =>
        _.every(
          filter,
          (value, field) =>
            _.isEmpty(value) ||
            (row?.[field] as string | boolean | number)
              ?.toString()
              ?.toLowerCase()
              ?.indexOf(value?.toString()?.toLowerCase() ?? '') > -1,
        ),
      )
      .sumBy(column);
  };

  function onLoadNext() {
    props.onChange({
      isAppend: true,
      skip: itemsCount,
    });
  }

  const subtitle = t(`{{totalCount}} Filtered Items for`, {
    totalCount,
  });

  return (
    <Container
      col
      overflow
      componentName={`LocationsStatsByRuleTableView`}
      className={`border-app-panel-dark flex-1 ltr:border-l rtl:border-r`}
    >
      <Container overflow col>
        <div className="sticky top-0 flex space-x-1">
          <StatGroup
            title={t`Capacity`}
            horizontal
            hasNoPadding
            className="flex h-full flex-col"
            classNameStatGroupItems="h-full"
            classNameTitle="px-2 py-1 !text-xxs !lg:text-xs"
            helpNavTo={
              'simulation/analyse/simulation-analyse-capacity-occupancy'
            }
          >
            <Stat
              isPreview
              inPanelMode
              className="p-1"
              hasNoPadding
              title={t`Locations`}
              value={formatInteger(getTotalBy('locationCount', {}))}
              isCheckLoading={isLoading}
            />

            <Stat
              isPreview
              inPanelMode
              className="p-1"
              hasNoPadding
              titleElement={
                <div className="z-menu -m-1 flex w-full items-center">
                  <DropdownSelector
                    className="text-xxs w-full lg:text-xs"
                    classNameValue="text-xxs"
                    w_sm
                    DropAlignRight
                    value={selectedPalletSpec}
                    values={locationCapacitySpec}
                    onChange={spec => {
                      setSelectedPalletSpec(spec);
                    }}
                    renderValue={spec => spec?.title ?? t`Select UOM`}
                  />
                </div>
              }
              value={formatInteger(
                getTotalBy(selectedPalletSpec?.id as LocationsStatsDataColumn, {
                  locationStatus: true,
                }),
              )}
              isCheckLoading={isLoading}
            />
          </StatGroup>
          <StatGroup
            title={t`Occupancy`}
            horizontal
            hasNoPadding
            className="flex h-full flex-col"
            classNameStatGroupItems="h-full"
            classNameTitle="px-2 py-1 !text-xxs !lg:text-xs"
            helpNavTo={
              'simulation/analyse/simulation-analyse-capacity-occupancy'
            }
          >
            <Stat
              isPreview
              inPanelMode
              className="p-1"
              hasNoPadding
              title={t`Occupied`}
              value={formatInteger(
                getTotalBy('locationCount', { assigned: 'true' }),
              )}
              isCheckLoading={isLoading}
            />
            <Stat
              isPreview
              inPanelMode
              className="p-1"
              hasNoPadding
              title={t`Empty`}
              value={formatInteger(
                getTotalBy('locationCount', { assigned: 'false' }),
              )}
              isCheckLoading={isLoading}
            />
          </StatGroup>
        </div>
        {/*
        <ActionBar className="px-4 py-2">
          <DropdownSelector
            className="text-sm mx-1"
            DropAlignLeft
            panelMode
            value={dimensionId}
            values={locationStatsDrillDownDimensions}
            onChange={dim => {
              setLocationsStatsData(null);
              setDimensionId(dim);
            }}
            renderValue={key => dimensions[key]?.title}
          />
        </ActionBar> */}

        <DatasetTable
          id={props.id}
          title={props.title}
          subtitle={subtitle}
          columnsConfig={columnsConfig}
          keyFields={keyFields}
          data={data}
          onLoadNext={onLoadNext}
          onSearch={setSearchValues}
          totalCount={totalCount}
          searchValues={effectiveSearchValues}
          isLoading={isLoading}
          isSticky
          hideScreenTitle={true}
          onStartExportClick={props.startExportCSV}
          classNameHeader="p-1 bg-app-panel-dark/60 mt-0.5 mb-1 mx-0.5"
          childrenHeader={
            <DropdownSelector
              className="mx-1 text-sm"
              DropAlignLeft
              panelMode
              value={dimensionId}
              values={locationStatsDrillDownDimensions}
              onChange={dim => {
                if (dim !== dimensionId) {
                  setLocationsStatsData(null);
                  setDimensionId(dim);
                }
              }}
              renderValue={key => dimensions[key]?.title}
            />
          }
        />
      </Container>
    </Container>
  );
};

export default LocationsStatsByRuleTableView;
