import {
  AllocateJobEngine,
  AllocationType,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { ANALYZE_PROGRESS_REFRESH_INTERVAL } from '../common/constants';
import LoadingIndicator from '../components/LoadingIndicator';
import { Button } from '../components/actions/Button';
import { ScreenTitle } from '../components/layout/ScreenTitle';
import useLoadAllocationRun from '../simulation/hooks/useLoadAllocationRun';
import useRunAllocation from '../simulation/hooks/useRunAllocation';
import AllocationSummary from '../simulation/panels/allocate/AllocationSummary';
import { getAllocationSettingsInput } from '../simulation/store/allocation/allocation.helper';
import { allocationRunSummary } from '../simulation/store/allocation/allocation.state';
import { allocationSettings } from '../simulation/store/allocationPolicy/allocationPolicy.state';

const AllocationPreview: React.FC = () => {
  const { t } = useTranslation('simulation');
  const currentSettings = useRecoilValue(allocationSettings);
  const allocation = useRecoilValue(allocationRunSummary);
  const [runAllocation] = useRunAllocation();
  const [loadAllocationRun] = useLoadAllocationRun();

  const isAllocating =
    allocation?.status === 'CALCULATING' ||
    allocation?.status === 'CREATED' ||
    allocation?.status === 'RESTARTED';

  useEffect(() => {
    if (isAllocating) {
      const timeoutId = setTimeout(() => {
        loadAllocationRun({ allocationRunId: allocation.id });
      }, ANALYZE_PROGRESS_REFRESH_INTERVAL);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [allocation, isAllocating]);

  async function updateAllocationDraft() {
    runAllocation({
      type: AllocationType.FULL,
      allocationId: allocation?.id,
      engine: AllocateJobEngine.LAMBDA,
    });
  }

  const hasError =
    allocation?.status === 'FAILED' || allocation?.status === 'TERMINATED';
  const isLoading = isAllocating;

  const isDraft = allocation?.status === 'CREATED';
  const isReady = allocation?.status === 'READY';

  const isChanged =
    isReady &&
    !_.isEqual(
      getAllocationSettingsInput(currentSettings),
      getAllocationSettingsInput(allocation?.allocationSettings),
    );

  // const changes = _.differenceWith(
  //   _.toPairs(getAllocationSettingsInput(currentSettings)),
  //   _.toPairs(getAllocationSettingsInput(allocation?.allocationSettings)),
  //   _.isEqual,
  // );

  // console.log('###Policy changes', changes);

  return (
    <>
      <header data-component="AllocationPreview">
        <ScreenTitle
          subtitle={t`Allocation`}
          title={t`Pre-Allocate`}
          isSticky={true}
        >
          <Button
            className="mx-2 rounded"
            buttonSize="xs"
            buttonType={hasError ? 'delete' : isChanged ? 'warning' : 'primary'}
            isDisabled={isLoading}
            isLoading={isLoading}
            hasIconBefore
            label={
              isLoading
                ? t`Allocating...`
                : isChanged
                  ? t`Re-generate`
                  : t`Allocate preview`
            }
            onPress={updateAllocationDraft}
          />
        </ScreenTitle>
      </header>
      {isLoading && <LoadingIndicator selfCenter message={t`Loading...`} />}
      {isReady && <AllocationSummary />}
    </>
  );
};

export default AllocationPreview;
