import { MappingSchema } from '../mapping';
import { MappingValueResolver } from '../valueResolver/valueResolverBase';

export enum ActivityEventProcessType {
  PICKING = 'PICKING',
  REPLENISHMENT = 'REPLENISHMENT',
  REASSIGN = 'REASSIGN',
  PUTAWAY = 'PUTAWAY',
  PACKING = 'PACKING',
  STACKING_DESTACKING = 'STACKING_DESTACKING',
  RECEIVING = 'RECEIVING',
  DISPATCH = 'DISPATCH',
  SORTING = 'SORTING',
  HANDLING = 'HANDLING',
  QUALITY_CONTROL = 'QUALITY_CONTROL',
  INVENTORY = 'INVENTORY',
  INVENTORY_COUNT = 'INVENTORY_COUNT',
  VALUE_ADD = 'VALUE_ADD',
  RETURNS_PROCESSING = 'RETURNS_PROCESSING',
  SHIPPING = 'SHIPPING',
  TRANSPORT = 'TRANSPORT',
  MAINTENANCE = 'MAINTENANCE',
  CROSSDOCKING = 'CROSSDOCKING',
  MANAGEMENT = 'MANAGEMENT',
  MANAGEMENT_YARD = 'MANAGEMENT_YARD',
  MANAGEMENT_DATA = 'MANAGEMENT_DATA',
  MANAGEMENT_WMS = 'MANAGEMENT_WMS',
  SYSTEM = 'SYSTEM',
  IDENTIFICATION = 'IDENTIFICATION',
  OTHER = 'OTHER',
  IGNORE = 'IGNORE',
}

export const ACTIVITY_EVENT_PROCESS_TYPE_ALL: ActivityEventProcessType[] =
  Object.values(ActivityEventProcessType).filter(
    type => type !== ActivityEventProcessType.IGNORE,
  );

export enum ActivityEventType {
  PICK = 'PICK',
  PUT = 'PUT',
  PACK = 'PACK',
  MOVE = 'MOVE',
  STACK = 'STACK',
  HOLD = 'HOLD',
  WAIT = 'WAIT',
  COUNT = 'COUNT',
  OTHER = 'OTHER',
  IGNORE = 'IGNORE',
  JOB_START = 'JOB_START',
  JOB_END = 'JOB_END',
}

export const ACTIVITY_EVENT_TYPE_ALL: ActivityEventType[] = Object.values(
  ActivityEventType,
).filter(type => type !== ActivityEventType.IGNORE);

export const eventProcessTypeValueResolver: MappingValueResolver<ActivityEventProcessType> =
  {
    picking: ActivityEventProcessType.PICKING,
    replenishment: ActivityEventProcessType.REPLENISHMENT,
    reassign: ActivityEventProcessType.REASSIGN,
    putaway: ActivityEventProcessType.PUTAWAY,
    packing: ActivityEventProcessType.PACKING,
    receiving: ActivityEventProcessType.RECEIVING,
    quality_control: ActivityEventProcessType.QUALITY_CONTROL,
    inventory_count: ActivityEventProcessType.INVENTORY_COUNT,
    returns_processing: ActivityEventProcessType.RETURNS_PROCESSING,
    shipping: ActivityEventProcessType.SHIPPING,
    maintenance: ActivityEventProcessType.MAINTENANCE,
    cross_docking: ActivityEventProcessType.CROSSDOCKING,
    yard_management: ActivityEventProcessType.MANAGEMENT_YARD,
    data_management: ActivityEventProcessType.MANAGEMENT_DATA,
    management: ActivityEventProcessType.MANAGEMENT,
    system: ActivityEventProcessType.SYSTEM,
    other: ActivityEventProcessType.OTHER,
    ignore: ActivityEventProcessType.IGNORE,
  };

export const eventTypeValueResolver: MappingValueResolver<ActivityEventType> = {
  pick: ActivityEventType.PICK,
  put: ActivityEventType.PUT,
  pack: ActivityEventType.PACK,
  count: ActivityEventType.COUNT,
  move: ActivityEventType.MOVE,
  other: ActivityEventType.OTHER,
  ignore: ActivityEventType.IGNORE,
  job_start: ActivityEventType.JOB_START,
  job_end: ActivityEventType.JOB_END,
};

export class ActivityFeedEvent {
  eventId: string;
  eventProcessType: ActivityEventProcessType;
  eventType: ActivityEventType;

  //what
  consignee?: string;
  sku?: string;
  uom?: string;
  quantity?: number;
  scanCode?: string;

  //previous location
  warehouseAreaSource?: string;
  locationIdSource?: string;

  //current location
  warehouseArea?: string;
  locationId: string;

  //who
  agentType: string; //mheType
  agentId?: string; //mheId
  agentUser?: string;
  agentEnergy?: number;
  agentSignalStrength?: number;

  //when
  eventStartTime?: Date;
  eventEndTime: Date;

  // picklist/job meta
  jobId?: string;
  jobLine?: number;
  jobGroupId?: string;
}

export const ACTIVITY_FEED_MAPPING_SCHEMA: MappingSchema<ActivityFeedEvent> = {
  partitionBy: [{ key: 'eventEndTime', transform: 'day' }],
  fields: [
    {
      name: 'eventId',
      type: 'string',
      unique: true,
    },
    {
      name: 'eventProcessType',
      type: { enumValues: ACTIVITY_EVENT_PROCESS_TYPE_ALL },
      defaultValueResolver: eventProcessTypeValueResolver,
    },
    {
      name: 'eventType',
      type: { enumValues: ACTIVITY_EVENT_TYPE_ALL },
      defaultValueResolver: eventTypeValueResolver,
    },

    {
      name: 'consignee',
      type: 'string',
      optional: true,
    },
    {
      name: 'sku',
      type: 'string',
      optional: true,
    },
    {
      name: 'uom',
      type: 'string',
      optional: true,
    },
    {
      name: 'quantity',
      type: 'integer',
      optional: true,
    },
    {
      name: 'scanCode',
      type: 'string',
      optional: true,
    },
    {
      name: 'warehouseAreaSource',
      type: 'string',
      optional: true,
    },
    {
      name: 'locationIdSource',
      type: 'string',
      optional: true,
    },

    {
      name: 'warehouseArea',
      type: 'string',
      optional: true,
    },
    {
      name: 'locationId',
      type: 'string',
    },
    {
      name: 'agentType',
      type: 'string',
    },
    {
      name: 'agentId',
      type: 'string',
      optional: true,
    },
    {
      name: 'agentUser',
      type: 'string',
      optional: true,
    },
    {
      name: 'agentEnergy',
      type: 'number',
      optional: true,
    },
    {
      name: 'agentSignalStrength',
      type: 'number',
      optional: true,
    },
    {
      name: 'eventStartTime',
      type: 'localDateTime',
      measureType: 'local-date-time',
      optional: true,
    },
    {
      name: 'eventEndTime',
      type: 'localDateTime',
      measureType: 'local-date-time',
    },

    {
      name: 'jobId',
      type: 'string',
      optional: false,
    },
    {
      name: 'jobLine',
      type: 'integer',
      optional: true,
    },
    {
      name: 'jobGroupId',
      type: 'string',
      optional: true,
    },
  ],
  includeRawData: true,
};
