import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { formatDateTime } from '../../common/formatHelper';
import useFormatter from '../../common/useFormatter';
import { ColumnConfig } from '../../components/DatasetTable';
import Tag from '../../helpContext/Tag';
import { ActualityAnalyzedJob } from '../store/datasetQueries/actualityAnalyzedJobs';
import {
  feedSelectedAgentId,
  feedSelectedPickListId,
} from '../store/feed.state';

function useActualityJobsTableConfig(): ColumnConfig<ActualityAnalyzedJob>[] {
  const { t } = useTranslation('dataset');
  const setSelectedJob = useSetRecoilState(feedSelectedPickListId);
  const setSelectedAgent = useSetRecoilState(feedSelectedAgentId);

  const formatter = useFormatter();

  function selectJob(job: ActualityAnalyzedJob) {
    setSelectedAgent(job.agentId);
    setSelectedJob(job.jobId);
  }

  const columnsConfig: ColumnConfig<ActualityAnalyzedJob>[] = [
    {
      field: 'jobId',
      title: t`Job Id`,
      hasFilter: true,
      render: (id: string, job) => {
        return (
          <div
            onClick={() => {
              selectJob(job);
            }}
          >
            <Tag className="text-xxs">{id}</Tag>
          </div>
        );
      },
    },
    {
      field: 'minEventTime',
      title: 'Started',
      hasFilter: true,
      hasSort: true,
      render: (v: Date) => formatDateTime(v),
    },
    {
      field: 'maxEventTime',
      title: 'Ended',
      hasFilter: true,
      hasSort: true,
      render: (v: Date) => formatDateTime(v),
    },

    {
      field: 'minPreviousEndTime',
      title: 'Previous',
      hasFilter: true,
      hasSort: true,
      render: (v: Date | null | undefined) => {
        console.log('minPreviousEndTime value:', v);
        console.log('minPreviousEndTime type:', typeof v);
        return v ? formatDateTime(v) : '-';
      },
    },

    {
      field: 'agentId',
      title: t`Agent`,
      hasSort: true,
      hasFilter: true,
    },

    {
      field: 'eventProcessType',
      title: 'Process Type',
      hasFilter: true,
      hasSort: true,
      render: (field: string) => <Tag className="text-xxs">{field}</Tag>,
    },
    {
      field: 'uomCount',
      title: t`UOM count`,
      hasSort: true,
    },
    {
      field: 'lines',
      title: t`Lines`,
    },
    {
      field: 'analyzedLines',
      title: t`Analyzed Lines`,
    },

    {
      field: 'duration',
      title: t`duration`,
      hasFilter: true,
      render: (v: number) => formatter.formatTimeSpan(v).fullString,
    },
    {
      field: 'cost',
      title: t`Cost`,
      hasFilter: true,
      render: (v: number) => formatter.formatCurrency(v).fullString,
    },
    {
      field: 'distance',
      title: t`Distance`,
      hasFilter: true,
      render: (v: number) => (v ? formatter.formatDistance(v).fullString : '-'),
    },
  ];

  return columnsConfig;
}
export default useActualityJobsTableConfig;
