import {
  AisleFeatureMetaFragment,
  BayPortal,
  BayTypeModelFragment,
  LayoutBayLocationFragment,
  LayoutConnectivityGraphFragment,
  LayoutDashboardItemFragment,
  LayoutFeatureFragment,
  LayoutLevelLocationFragment,
  LayoutPLaneFragment,
  LoadBayDetailsDocument,
  LoadBayDetailsQuery,
  LoadBayDetailsQueryVariables,
  LoadLayoutAislesMetaDocument,
  LoadLayoutAislesMetaQuery,
  LoadLayoutAislesMetaQueryVariables,
  LoadLayoutBayLocationsDocument,
  LoadLayoutBayLocationsQuery,
  LoadLayoutBayLocationsQueryVariables,
  LoadLayoutLevelLocationsDocument,
  LoadLayoutLevelLocationsQuery,
  LoadLayoutLevelLocationsQueryVariables,
  LoadLayoutNavigableFeaturesDocument,
  LoadLayoutNavigableFeaturesQuery,
  LoadLayoutNavigableFeaturesQueryVariables,
  LoadPlaneAislesDocument,
  LoadPlaneAislesQuery,
  LoadPlaneAislesQueryVariables,
  LoadPlaneBaysDocument,
  LoadPlaneBaysQuery,
  LoadPlaneBaysQueryVariables,
  LocationTypeModelFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector } from 'recoil';
import { secureClient } from '../../../GraphQLClient';
import { persistAtom } from '../../../common/recoil/persistAtom';
import { AsyncLoadStatus } from '../../../common/types';
import {
  AisleFeatureFragment,
  BayFeatureDetailedFragment,
  BayFeatureFragment,
} from '../../../import/layout/converter/converter.serializable.model';
import { getPlanesStats } from '../../store/layout.helper';
import { LayoutPlane, PlaneStats } from '../../store/layout.types';
import {
  CachingSettings,
  ViewerCacheSize,
  cachingSettingsDefault,
} from './viewer.helper';

const getKey = (postfix: string) => `layout-viewer-${postfix}`;

export const viewerLayoutId = atom<string>({
  key: getKey('id'),
  default: null,
});

export const viewerSelectedLayout = atom<LayoutDashboardItemFragment>({
  key: getKey('selected-layout'),
  default: null,
});

export const viewerPlanes = atom<LayoutPlane[]>({
  key: getKey('visual-planes-all'),
  default: null,
});

export const viewerPlanesMap = selector<Record<string, LayoutPlane>>({
  key: getKey('visual-planes-all-map'),
  get: ({ get }) => {
    return _.keyBy(get(viewerPlanes), p => p.id);
  },
});

export const viewerPlanesTitles = selector<Record<string, string>>({
  key: getKey('visual-planes-titles'),
  get: ({ get }) =>
    _.reduce(get(viewerPlanes), (acc, p) => ({ ...acc, [p.id]: p.title }), {}),
});

export const viewerLocationTypes = atom<
  Record<string, LocationTypeModelFragment>
>({
  key: getKey('location-types-all'),
  default: null,
});

export const viewerBayModels = atom<Record<string, BayTypeModelFragment>>({
  key: getKey('bay-models-all'),
  default: null,
});

export const viewerConnectivityGraph = atom<LayoutConnectivityGraphFragment>({
  key: getKey('connectivity-graph'),
  default: null,
});

export const viewerSelectedPlaneIdAtom = atom<string>({
  key: getKey('selected-plane-id-atom'),
  default: null,
});

export const viewerSelectedPlaneId = selector<string>({
  key: getKey('selected-plane-id'),
  get: ({ get }) => {
    const current = get(viewerSelectedPlaneIdAtom);
    const planes = get(viewerPlanes);
    if (_.isNil(planes)) return null;

    // planes list are filled and it contains currently selected plane id
    if (_.some(planes, p => p.id === current)) {
      return current;
    }
    // return first plane id
    else {
      return _.head(planes)?.id;
    }
  },
  set: ({ get, set }, areaId) => {
    set(viewerSelectedAisleIdAtom, null);
    set(viewerSelectedBayIdAtom, null);
    set(viewerSelectedLocationIdAtom, null);
    set(viewerSelectedPlaneIdAtom, areaId);
  },
});

export const viewerSelectedPlane = selector<LayoutPLaneFragment>({
  key: getKey('selected-plane'),
  get: ({ get }) => {
    const planeId = get(viewerSelectedPlaneId);
    return get(viewerPlanesMap)?.[planeId];
  },
});

export const viewerSelectedBayIdAtom = atom<string>({
  key: getKey('selected-bay-id'),
  default: null,
});

export const viewerSelectedBay = selector<BayFeatureFragment>({
  key: getKey('get-selected-bay'),
  get: ({ get }) => {
    const selectedBayId = get(viewerSelectedBayIdAtom);
    return selectedBayId ? get(viewerAreaBays)?.[selectedBayId] : null;
  },
});

export const viewerAreaBays = selector<Record<string, BayFeatureFragment>>({
  key: getKey('area-visual-bays'),
  get: async ({ get }) => {
    const layoutId = get(viewerLayoutId);
    const planeId = get(viewerSelectedPlaneId);
    if (!layoutId || !planeId) return null;
    try {
      const response = await secureClient.query<
        LoadPlaneBaysQuery,
        LoadPlaneBaysQueryVariables
      >({
        query: LoadPlaneBaysDocument,
        variables: {
          layoutId,
          planeId,
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error("Cannot load plane's bays", response.errors);
        throw new Error("Cannot load plane's bays");
      }
      const bays = response.data.layout.features;
      const bayMap = _.keyBy(bays, b => b.id);
      return bayMap as any;
    } catch (ex) {
      console.error("Cannot load plane's bays", ex);
      throw new Error("Cannot load plane's bays");
    }
  },
});

export const viewerSelectedBayDetails = selector<BayFeatureDetailedFragment>({
  key: getKey('selected-bay-details'),
  get: async ({ get }) => {
    const layoutId = get(viewerLayoutId);
    const planeId = get(viewerSelectedPlaneId);
    const bayId = get(viewerSelectedBayIdAtom);
    if (!layoutId || !planeId || !bayId) return null;
    try {
      const response = await secureClient.query<
        LoadBayDetailsQuery,
        LoadBayDetailsQueryVariables
      >({
        query: LoadBayDetailsDocument,
        variables: {
          layoutId,
          planeId,
          bayId,
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error("Cannot load plane's bay details ", response.errors);
        throw new Error("Cannot load plane's bay details ");
      }
      const bay = _.head(response.data.layout.features);
      return bay as any as BayFeatureDetailedFragment;
    } catch (ex) {
      console.error("Cannot load plane's bay details ", ex);
      throw new Error("Cannot load plane's bay details ");
    }
  },
});

export const viewerAreaAisles = selector<Record<string, AisleFeatureFragment>>({
  key: getKey('area-visual-aisles'),
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const layoutId = get(viewerLayoutId);
    const planeId = get(viewerSelectedPlaneId);
    if (!layoutId || !planeId) return null;

    try {
      const response = await secureClient.query<
        LoadPlaneAislesQuery,
        LoadPlaneAislesQueryVariables
      >({
        query: LoadPlaneAislesDocument,
        variables: {
          layoutId,
          planeId,
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error('Cannot load aisles by plane', response.errors);
        throw new Error('Cannot load aisles by plane');
      }
      const aisles = response.data.layout
        .features as any as AisleFeatureFragment[];
      const aislesMap = _.keyBy(aisles, a => a.id);
      return aislesMap;
    } catch (ex) {
      console.error('Cannot load aisles by plane', ex);
      throw new Error('Cannot load aisles by plane');
    }
  },
});

export const viewerAislesMeta = selector<
  Record<string, AisleFeatureMetaFragment>
>({
  key: getKey('area-visual-aisles-titles'),
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const layoutId = get(viewerLayoutId);

    if (!layoutId) return null;

    try {
      const response = await secureClient.query<
        LoadLayoutAislesMetaQuery,
        LoadLayoutAislesMetaQueryVariables
      >({
        query: LoadLayoutAislesMetaDocument,
        variables: {
          layoutId,
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error('Cannot load aisles info', response.errors);
        throw new Error('Cannot load aisles info');
      }
      const aisles = response.data.layout.features;
      const aislesMap = _.keyBy(aisles, a => a.id);
      return aislesMap;
    } catch (ex) {
      console.error('Cannot load aisles by plane', ex);
      throw new Error('Cannot load aisles by plane');
    }
  },
});

export const viewerPlaneStats = selector<Record<string, PlaneStats>>({
  key: getKey('plane-stats'),
  get: ({ get }) => {
    const aisles = get(viewerAislesMeta);
    return getPlanesStats(_.values(aisles));
  },
});

export const viewerSelectedLevelInner = atom<number>({
  key: getKey('area-selected-level-inner'),
  default: 0,
});

export const viewerSelectedLevel = selector<number>({
  key: getKey('area-selected-level'),
  get: ({ get }) => {
    return get(viewerSelectedLevelInner);
  },
  set: ({ set }, value) => {
    set(viewerShowLocations, true);
    set(viewerSelectedLocationIdAtom, null);
    set(viewerSelectedLevelInner, value);
  },
});

export const viewerLevelLocations = selector<
  Record<string, LayoutLevelLocationFragment>
>({
  key: getKey('level-locations'),
  get: async ({ get }) => {
    const layoutId = get(viewerLayoutId);
    const planeId = get(viewerSelectedPlaneIdAtom);
    const level = get(viewerSelectedLevelInner);
    if (_.isNil(layoutId) || _.isNil(planeId)) return null;

    try {
      const response = await secureClient.query<
        LoadLayoutLevelLocationsQuery,
        LoadLayoutLevelLocationsQueryVariables
      >({
        query: LoadLayoutLevelLocationsDocument,
        variables: {
          id: layoutId,
          planeId,
          level,
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error('Cannot load locations by level', response.errors);
        throw new Error('Cannot load locations by level');
      }
      const locations = response.data.layout.locations.content;
      if (_.isEmpty(locations)) {
        return null;
      }

      const locMap = _.keyBy(locations, l => l.locationId);
      return locMap;
    } catch (ex) {
      console.error('Cannot load locations by level', ex);
      throw new Error('Cannot load locations by level');
    }
  },
});

export const viewerBayLocations = selector<
  Record<string, LayoutBayLocationFragment>
>({
  key: getKey('bay-locations'),
  get: async ({ get }) => {
    const layoutId = get(viewerLayoutId);
    const bayId = get(viewerSelectedBayIdAtom);

    if (!layoutId || !bayId) return null;

    try {
      const response = await secureClient.query<
        LoadLayoutBayLocationsQuery,
        LoadLayoutBayLocationsQueryVariables
      >({
        query: LoadLayoutBayLocationsDocument,
        variables: {
          id: layoutId,
          bayId: bayId,
        },
      });
      if (!_.isEmpty(response.errors)) {
        console.error('Cannot load locations by level', response.errors);
        throw new Error('Cannot load locations by level');
      }
      const locations = response.data.layout.locations.content;
      if (_.isEmpty(locations)) {
        return null;
      }
      return _.keyBy(locations, loc => loc.locationId);
    } catch (ex) {
      console.error('Cannot load locations by level', ex);
      throw new Error('Cannot load locations by level');
    }
  },
});

const viewerShowLocationsAtom = atom<boolean>({
  key: getKey('show-level-locations-atom'),
  default: true,
});

export const viewerShowLocations = selector<boolean>({
  key: getKey('show-level-locations'),
  get: ({ get }) => get(viewerShowLocationsAtom),
  set: ({ set }, value) => {
    if (value !== true) {
      set(viewerSelectedLocationIdAtom, null);
    }
    set(viewerShowLocationsAtom, value);
  },
});

export const viewerShowPortals = atom<boolean>({
  key: getKey('show-portals'),
  default: false,
});

export const viewerShowLocationTitles = persistAtom<boolean>({
  key: getKey('show-location-titles'),
  default: true,
});

export const viewerShowMultiselectToolbar = atom<boolean>({
  key: getKey('show-multiselect-toolbar'),
  default: false,
});

const viewerLocationMultiselectModeEnabledAtom = atom<boolean>({
  key: getKey('locations-multiselect-mode-enabled-atom'),
  default: false,
});

export const viewerLocationMultiselectModeEnabled = selector<boolean>({
  key: getKey('locations-multiselect-mode-enabled'),
  get: ({ get }) => get(viewerLocationMultiselectModeEnabledAtom),
  set: ({ set }, value) => {
    set(viewerSelectedLocationIdAtom, null);
    set(viewerMultiselectedLocationIds, {});
    set(viewerLocationMultiselectModeEnabledAtom, value);
  },
});

export const viewerSelectedAisleIdAtom = atom<string>({
  key: getKey('selected-aisle-id'),
  default: null,
});

export const viewerSelectedAisle = selector<AisleFeatureFragment>({
  key: getKey('selected-aisle-selector'),
  get: ({ get }) => {
    const selectedAisleId = get(viewerSelectedAisleIdAtom);
    return selectedAisleId ? get(viewerAreaAisles)?.[selectedAisleId] : null;
  },
  set: ({ set }, aisle: AisleFeatureFragment) => {
    set(viewerSelectedBayIdAtom, null);
    set(viewerSelectedLocationIdAtom, null);
    set(viewerSelectedAisleIdAtom, aisle?.id);
  },
});

export const viewerSelectedAisleWithToggle = selector<AisleFeatureFragment>({
  key: getKey('selected-aisle-selector-toggle'),
  get: ({ get }) => {
    const selectedAisleId = get(viewerSelectedAisleIdAtom);
    return selectedAisleId ? get(viewerAreaAisles)?.[selectedAisleId] : null;
  },
  set: ({ get, set }, aisle: AisleFeatureFragment) => {
    const selectedAisleId = get(viewerSelectedAisleIdAtom);
    set(viewerSelectedBayIdAtom, null);
    set(viewerSelectedLocationIdAtom, null);
    set(
      viewerSelectedAisleIdAtom,
      aisle?.id === selectedAisleId ? null : aisle?.id,
    );
  },
});

export const viewerSelectedBayIdWithToggle = selector<string>({
  key: getKey('bay-id-selector-toggle'),
  get: ({ get }) => get(viewerSelectedBayIdAtom),
  set: ({ get, set }, value: string) => {
    const selectedBayId = get(viewerSelectedBayIdAtom);
    set(viewerSelectedBayIdAtom, selectedBayId === value ? null : value);
  },
});

export const viewerSelectedLocationIdAtom = atom<string>({
  key: getKey('selected-location-id-atom'),
  default: null,
});

export const viewerSelectedLocationIdWithToggle = selector<string>({
  key: getKey('selected-location-id'),
  get: ({ get }) => get(viewerSelectedLocationIdAtom),
  set: ({ get, set }, locationId: string) => {
    const current = get(viewerSelectedLocationIdAtom);
    const multiselectEnabled = get(viewerLocationMultiselectModeEnabled);
    if (multiselectEnabled) {
      // multiselect
      const current = get(viewerMultiselectedLocationIds) ?? {};
      const wasSelected = _.has(current, locationId);
      const newLocations = wasSelected
        ? _.omit(current, locationId)
        : {
            ...current,
            [locationId]: locationId,
          };
      set(viewerMultiselectedLocationIds, newLocations);
    } else {
      // single select mode
      set(
        viewerSelectedLocationIdAtom,
        locationId !== current ? locationId : null,
      );
    }
  },
});

export const viewerSelectedLocation = selector<LayoutLevelLocationFragment>({
  key: getKey('selected-location'),
  get: ({ get }) => {
    const locId = get(viewerSelectedLocationIdAtom);
    return get(viewerLevelLocations)?.[locId];
  },
});

export const viewerSelectedBayLocation = selector<LayoutBayLocationFragment>({
  key: getKey('selected-bay-location'),
  get: ({ get }) => {
    const locId = get(viewerSelectedLocationIdAtom);
    return get(viewerBayLocations)?.[locId];
  },
});

const viewerMultiselectedLocationIdsAtom = atom<Record<string, string>>({
  key: getKey('locations-id-multiselected-atom'),
  default: {},
});

export const viewerMultiselectedLocationIds = selector<Record<string, string>>({
  key: getKey('locations-id-multiselected'),
  get: ({ get }) => {
    const isEnabled = get(viewerLocationMultiselectModeEnabled);
    return isEnabled ? get(viewerMultiselectedLocationIdsAtom) : {};
  },
  set: ({ set }, value) => {
    set(viewerMultiselectedLocationIdsAtom, value);
  },
});

export const viewerBayPortalDictionary = selector<Record<string, BayPortal>>({
  key: getKey('bay-portal-dictionary'),
  get: ({ get }) => {
    const cg = get(viewerConnectivityGraph);
    if (_.isNil(cg)) return {};
    return _(cg.planes)
      .flatMap(a => a.bayPortals)
      .keyBy(portal => portal.bayId)
      .value();
  },
});

export const viewerShowTooltip = atom<boolean>({
  key: getKey('showTooltip'),
  default: true,
});

export const viewerHoveredFeatureId = atom<string>({
  key: getKey('hovered-feature-id'),
  default: null,
});

export const viewerHoveredBay = selector<BayFeatureFragment>({
  key: getKey('hovered-bay'),
  get: ({ get }) => {
    const featureId = get(viewerHoveredFeatureId);
    const baysMap = get(viewerAreaBays) ?? {};
    return _.isNil(featureId) ? null : baysMap?.[featureId];
  },
  set: ({ get, set }, value: BayFeatureFragment) =>
    set(viewerHoveredFeatureId, value?.id),
});

export const viewerHoveredAisle = selector<AisleFeatureFragment>({
  key: getKey('hovered-aisle'),
  get: ({ get }) => {
    const featureId = get(viewerHoveredFeatureId);
    const aislesMap = get(viewerAreaAisles) ?? {};
    return _.isNil(featureId) ? null : aislesMap?.[featureId];
  },
  set: ({ get, set }, value: AisleFeatureFragment) =>
    set(viewerHoveredFeatureId, value?.id),
});

export const viewerHoveredLocation = atom<LayoutLevelLocationFragment>({
  key: getKey('hovered-location'),
  default: null,
});

export const viewerAutoSizeId = atom<string>({
  key: getKey('autosize-id'),
  default: null,
});

export const viewerLayoutCachingSettings = persistAtom<CachingSettings>({
  key: getKey('caching-settings'),
  default: cachingSettingsDefault,
});

export const viewerAppliedCachingSettings = selector<CachingSettings>({
  key: getKey('cache-pixel-ratio'),
  get: ({ get }) => {
    const plane = get(viewerSelectedPlane);
    const settings = get(viewerLayoutCachingSettings);
    if (_.isNil(plane)) {
      return settings;
    }

    const w = plane.boundingBox[2] - plane.boundingBox[0];
    const h = plane.boundingBox[3] - plane.boundingBox[1];
    const ratio = Math.min(1, ViewerCacheSize[settings.size] / (w * h));
    return {
      ...settings,
      ratio,
    };
  },
});

export const viewerLocationDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('location-data-load-status'),
  default: AsyncLoadStatus.None,
});

export const viewerLayoutNavigableFeaturesMeta = selector<
  Record<string, LayoutFeatureFragment>
>({
  key: getKey('features-dictionary'),
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  get: async ({ get }) => {
    const layoutId = get(viewerLayoutId);
    if (_.isNil(layoutId)) return null;
    try {
      const response = await secureClient.query<
        LoadLayoutNavigableFeaturesQuery,
        LoadLayoutNavigableFeaturesQueryVariables
      >({
        query: LoadLayoutNavigableFeaturesDocument,
        variables: { layoutId },
      });
      if (!_.isEmpty(response.errors)) {
        console.error('Cannot load Layout features', response.errors);
        throw new Error('Cannot load Layout features');
      }
      const features = response.data.layout.features;
      const featuresMap = _.keyBy(features, a => a.id);
      return featuresMap;
    } catch (ex) {
      console.error('Cannot load Layout features', ex);
      throw new Error('Cannot load Layout features');
    }
  },
});
