import { ActivityEventProcessType } from '@warebee/shared/import-converter';
import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Button } from '../../components/actions/Button';
import ButtonSwitchMulti from '../../components/actions/ButtonSwitchMulti';
import { Spacer } from '../../components/layout/Spacer';
import { ActionBar } from '../../components/nav/ActionBar';
import PanelContainer from '../../containers/PanelContainer';
import useActivityEventProcessTypeDetails from '../../import/activityFeed/schema/useActivityEventProcessTypeDetails';
import { feedDisabledProcessTypes } from '../store/feed.state';
const ProcessTypeFilters: React.FC = () => {
  const { t } = useTranslation('feed');
  const [disabledProcessType, setDisabledProcessType] = useRecoilState(
    feedDisabledProcessTypes,
  );

  const [disabledProcessTypesLocal, setDisabledProcessTypesLocal] =
    useState<ActivityEventProcessType[]>();

  const disabledProcessSet = new Set(disabledProcessTypesLocal);

  const processTypesDescriptors = useActivityEventProcessTypeDetails();

  useEffect(() => {
    setDisabledProcessTypesLocal(disabledProcessType);
  }, [disabledProcessType]);

  function toggleProcessType(id: ActivityEventProcessType, isSelect: boolean) {
    const others = _.filter(disabledProcessTypesLocal, at => at !== id);
    if (!isSelect) {
      others.push(id);
    }
    setDisabledProcessTypesLocal(others);
  }

  return (
    <PanelContainer
      id="panel-feed-global-filters-process-type"
      title={t`Process types filters`}
      collapsible
      hasPadding
    >
      <ul className={classNames('mb-6 space-y-6')}>
        {_.map(processTypesDescriptors, processType => {
          const isDisabled =
            disabledProcessSet.size !== 0 &&
            disabledProcessSet.has(processType.id);
          return (
            <div
              className={classNames('flex')}
              key={`process-type-global-filter-${processType.id}`}
            >
              <label className={classNames('flex-1 opacity-80')}>
                {processType.id}
              </label>
              <ButtonSwitchMulti
                autoSize
                options={[{ label: t('off') }, { label: t('on') }]}
                selectedIndex={isDisabled ? 0 : 1}
                onClick={v => toggleProcessType(processType.id, v === 1)}
              />
            </div>
          );
        })}
      </ul>
      <ActionBar sticky stickyTop>
        <Spacer flexspace />
        <Button
          label={t`Apply filter`}
          buttonType="primary"
          buttonSize="xs"
          className="!py-2"
          onPress={() => setDisabledProcessType(disabledProcessTypesLocal)}
        />
      </ActionBar>
    </PanelContainer>
  );
};

export default ProcessTypeFilters;
