import {
  AllocationPolicyFragment,
  AllocationRuleFragment,
  AllocationSettingsFragment,
  DeallocationPolicyFragment,
  DeallocationRuleFragment,
  LocationFilterIntersectionFragment,
  SimulationItemFilterIntersectionFragment,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import {
  ALLOCATION_POLICY_DEFAULT_RULE_ID,
  DEALLOCATION_POLICY_DEFAULT_RULE_ID,
} from '../allocation/allocation.helper';
import { simulationCurrent } from '../simulation.state';
import {
  AllocationPolicySelectedIdentity,
  DeallocationPolicySelectedIdentity,
} from './allocationPolicy.types';

const getKey = (postfix: string) => `warebee-simulation-allocation-${postfix}`;

export const allocationSettings = selector<AllocationSettingsFragment>({
  key: getKey('settings'),
  get: ({ get }) => get(simulationCurrent).allocationSettings,
  set: ({ set }, value: AllocationSettingsFragment) => {
    set(simulationCurrent, current => ({
      ...current,
      allocationSettings: value,
    }));
  },
});

export const allocationPolicy = selector<AllocationPolicyFragment>({
  key: getKey('allocation-policy'),
  get: ({ get }) => get(allocationSettings).allocationPolicy,
  set: ({ set }, value: AllocationPolicyFragment) =>
    set(allocationSettings, current => ({
      ...current,
      allocationPolicy: value,
    })),
});

export const allocationPolicySelectedIdentity =
  atom<AllocationPolicySelectedIdentity>({
    key: getKey('allocation-selected-identity'),
    default: null,
  });

export const allocationPolicyDefaultRule = selector<AllocationRuleFragment>({
  key: getKey('allocation-rule-default'),
  get: ({ get }) => get(allocationPolicy)?.defaultRule,
  set: ({ get, set }, value: AllocationRuleFragment) => {
    set(allocationPolicy, current => ({
      ...current,
      defaultRule: value,
    }));
  },
});

export const allocationPolicyRule = selectorFamily<
  AllocationRuleFragment,
  string
>({
  key: getKey('allocation-rule-by-id'),
  get:
    ruleId =>
    ({ get }) => {
      if (ruleId === ALLOCATION_POLICY_DEFAULT_RULE_ID) {
        return get(allocationPolicyDefaultRule);
      }
      const settings = get(allocationPolicy);
      return _.find(settings?.rules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, value: AllocationRuleFragment) => {
      if (ruleId === ALLOCATION_POLICY_DEFAULT_RULE_ID) {
        return set(allocationPolicyDefaultRule, value);
      } else {
        set(allocationPolicy, current => ({
          ...current,
          rules: current.rules.map(rule => (rule.id === ruleId ? value : rule)),
        }));
      }
    },
});

export const allocationPolicyProductFilterIntersection = selectorFamily<
  SimulationItemFilterIntersectionFragment,
  AllocationPolicySelectedIdentity
>({
  key: getKey('allocation-product-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) return null;

      const rule = get(allocationPolicyRule(identity.ruleId));
      if (!rule) return null;

      const allFilters = rule?.itemsMatch?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.filterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: SimulationItemFilterIntersectionFragment) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(allocationPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(allocationPolicyRule(identity.ruleId), {
        ...rule,
        itemsMatch: {
          ...(rule?.itemsMatch ?? {}),
          anyOf: _.map(rule?.itemsMatch?.anyOf, fs =>
            fs.id === identity.filterId ? value : fs,
          ),
        },
      });
    },
});

export const allocationPolicyLocationFilterIntersection = selectorFamily<
  LocationFilterIntersectionFragment,
  AllocationPolicySelectedIdentity
>({
  key: getKey('allocation-location-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) return null;

      const rule = get(allocationPolicyRule(identity.ruleId));
      if (!rule) return null;

      const allFilters = rule?.locationsMatch?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.filterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: LocationFilterIntersectionFragment) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(allocationPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(allocationPolicyRule(identity.ruleId), {
        ...rule,
        locationsMatch: {
          ...(rule?.locationsMatch ?? {}),
          anyOf: _.map(rule?.locationsMatch?.anyOf, fs =>
            fs.id === identity.filterId ? value : fs,
          ),
        },
      });
    },
});

/**
 * De-Allocation Policy
 */
export const deallocationPolicy = selector<DeallocationPolicyFragment>({
  key: getKey('deallocation-policy'),
  get: ({ get }) => {
    return get(allocationSettings)?.deallocationPolicy;
  },
  set: ({ set }, value: DeallocationPolicyFragment) => {
    set(allocationSettings, current => ({
      ...current,
      deallocationPolicy: value,
    }));
  },
});

export const deallocationPolicySelectedIdentity =
  atom<DeallocationPolicySelectedIdentity>({
    key: getKey('deallocation-policy-selected-identity'),
    default: null,
  });

export const deallocationPolicyDefaultRule = selector<DeallocationRuleFragment>(
  {
    key: getKey('deallocation-rule-default'),
    get: ({ get }) => get(deallocationPolicy)?.defaultRule,
    set: ({ get, set }, value: DeallocationRuleFragment) => {
      set(deallocationPolicy, current => ({
        ...current,
        defaultRule: value,
      }));
    },
  },
);

export const deallocationPolicyRule = selectorFamily<
  DeallocationRuleFragment,
  string
>({
  key: getKey('deallocation-rule-by-id'),
  get:
    ruleId =>
    ({ get }) => {
      if (ruleId === DEALLOCATION_POLICY_DEFAULT_RULE_ID) {
        return get(deallocationPolicyDefaultRule);
      }
      const policy = get(deallocationPolicy);
      return _.find(policy?.rules, rule => rule.id === ruleId);
    },
  set:
    ruleId =>
    ({ get, set }, value: DeallocationRuleFragment) => {
      if (ruleId === DEALLOCATION_POLICY_DEFAULT_RULE_ID) {
        return set(deallocationPolicyDefaultRule, value);
      } else {
        const policy = get(deallocationPolicy);
        set(deallocationPolicy, {
          ...policy,
          rules: policy.rules.map(rule => (rule.id === ruleId ? value : rule)),
        });
      }
    },
});

export const deallocationPolicyProductFilterIntersection = selectorFamily<
  SimulationItemFilterIntersectionFragment,
  DeallocationPolicySelectedIdentity
>({
  key: getKey('product-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) return null;

      const rule = get(deallocationPolicyRule(identity.ruleId));
      if (!rule) return null;

      const allFilters = rule?.itemsMatch?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.filterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: SimulationItemFilterIntersectionFragment) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(deallocationPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(deallocationPolicyRule(identity.ruleId), {
        ...rule,
        itemsMatch: {
          ...(rule?.itemsMatch ?? {}),
          anyOf: _.map(rule?.itemsMatch?.anyOf, fs =>
            fs.id === identity.filterId ? value : fs,
          ),
        },
      });
    },
});

export const deallocationPolicyLocationFilterIntersection = selectorFamily<
  LocationFilterIntersectionFragment,
  DeallocationPolicySelectedIdentity
>({
  key: getKey('product-filter-intersection-by-id'),
  get:
    identity =>
    ({ get }) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) return null;

      const rule = get(deallocationPolicyRule(identity.ruleId));
      if (!rule) return null;

      const allFilters = rule?.locationsMatch?.anyOf;

      const filterIntersection = _.find(
        allFilters,
        fs => fs.id === identity.filterId,
      );
      return filterIntersection;
    },
  set:
    identity =>
    ({ get, set }, value: LocationFilterIntersectionFragment) => {
      if (_.isNil(identity?.filterId) || _.isNil(identity?.ruleId)) {
        throw new Error('Invalid parameters. Filter identity is invalid');
      }

      const rule = get(deallocationPolicyRule(identity.ruleId));
      if (!rule) return null;

      set(deallocationPolicyRule(identity.ruleId), {
        ...rule,
        locationsMatch: {
          ...(rule?.locationsMatch ?? {}),
          anyOf: _.map(rule?.locationsMatch?.anyOf, fs =>
            fs.id === identity.filterId ? value : fs,
          ),
        },
      });
    },
});

export const deallocationPolicyCheckResult = atom<Object>({
  key: getKey('check-result'),
  default: null,
});
