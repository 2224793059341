import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { Button } from '../../components/actions/Button';
import { TabItem } from '../../components/actions/Tabs';
import { InputGroupList } from '../../components/inputs/InputGroupList';
import { InputGroupParam } from '../../components/inputs/InputGroupParam';
import InputNumber from '../../components/inputs/InputNumber';
import { Container } from '../../components/layout/ContainerFlex';
import ContainerViewport from '../../components/layout/ContainerViewport';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import TitleSection from '../../components/layout/TitleSection';
import { getFilterConfiguration } from '../../simulation/store/resourcePolicy.helper';
import { ResourcePolicyEditMode } from '../../simulation/store/resourcePolicy.types';
import { warehouseSelected } from '../../store/warehouse.state';
import {
  AgentCategoryId,
  AgentMetadata,
  AgentSettingsWithMeta,
} from '../agentData/agent.types';
import { getAgentIcon, getModelSettings } from '../agentData/agentDataHelper';
import { agentCategoriesMap } from '../data/categories/categories';
import { agentModelsByCategory, agentModelsMap } from '../data/models/models';
import AgentSettingsSection from './AgentSettingsSection';
import AgentSettingsSpecialSection from './AgentSettingsSpecialSection';
import ResourcePolicyEmptyView from './ResourcePolicyEmptyView';

export type ResourcePolicyAgentViewProps = {
  agent?: AgentSettingsWithMeta;
  setAgent?: (agent: AgentSettingsWithMeta) => void;
  editMode: ResourcePolicyEditMode;
  uoms: string[];
};

const ResourcePolicyAgentView: React.FC<
  ResourcePolicyAgentViewProps
> = props => {
  const { t } = useTranslation('simulation');
  const wh = useRecoilValue(warehouseSelected);
  const { uoms } = props;

  const [visibleSections, setVisibleSection] = useState<
    Record<number, boolean>
  >({});
  const sectionsRef = useRef([]);
  const { agent, setAgent } = props;

  useEffect(() => {
    // check potential obsolete values received from server
    if (
      !_.isNil(agent?.resourceTypeId) &&
      _.isNil(agentCategoriesMap[agent.resourceTypeId])
    ) {
      const combinedSettings = {
        ...agent,
        resourceTypeId: 'picker-manual',
      };
      setAgent(combinedSettings);
    }
  }, [agent?.resourceTypeId]);

  const active =
    (visibleSections[0] ? '0' : _.max(_.keys(visibleSections))) ?? '0';

  if (!agent) {
    return <ResourcePolicyEmptyView />;
  }

  const categoryId: AgentCategoryId = agent.resourceTypeId as AgentCategoryId;

  const meta = (agent?.metadata ?? {}) as AgentMetadata;
  const models = agentModelsByCategory[categoryId];
  const modelsMap = _.keyBy(models, model => model.id);
  const modelId = meta.modelId ?? _.head(_.keys(modelsMap));

  const agentTypeDefaults = getModelSettings(
    modelId,
    uoms,
    wh.measurementSystem,
    wh.currency,
  );

  function changeAgentProperty(value: number | object, path: string) {
    const newAgentSettings = _.set(_.cloneDeep(agent), path, value);
    setAgent(newAgentSettings);
  }

  function changeModel(modelId: string) {
    const settings = getModelSettings(
      modelId,
      uoms,
      wh.measurementSystem,
      wh.currency,
    );

    const newModel = agentModelsMap[modelId];

    const combinedSettings = {
      ...agent,
      ...settings,
      resourceTypeId: newModel.categoryId,
      metadata: {
        ...(agent.metadata ?? {}),
        modelId: modelId,
      },
    };

    // restore Agent Amount
    combinedSettings.utilisation.agentAmount = agent.utilisation.agentAmount;

    setAgent(combinedSettings);
  }

  const filterConfiguration = getFilterConfiguration({
    agent,
    wh,
    uoms,
    t,
  });

  const subtitle = t`Agent properties`;

  const firstSection: TabItem = {
    id: `agent-settings-tab-first`,
    title: t`Properties`,
    content: (
      <AgentSettingsSpecialSection
        agentDefaults={agentTypeDefaults}
        agentSettings={agent}
        onChangeProperty={changeAgentProperty}
        onChangeModel={changeModel}
        editMode={props.editMode}
      />
    ),
  };
  const restSections: TabItem[] = filterConfiguration.map((section, index) => ({
    id: `agent-settings-tab-${index}`,
    title: section.title,
    content: (
      <AgentSettingsSection
        key={`agent-section-${section.title}`}
        section={section}
        agentDefaults={agentTypeDefaults}
        agentSettings={agent}
        onChangeProperty={changeAgentProperty}
        editMode={props.editMode}
      />
    ),
  }));

  // const filterSections: TabItem[] = filterConfiguration
  //   .map((section, sectionIndex) => {
  //     return section.filterGroups.map((filter, filterIndex) => ({
  //       id: `agent-subsection-${sectionIndex}-${filterIndex}`,
  //       title: filter.title,
  //       content: <span>{filter.title}</span>,
  //     }));
  //   })
  //   .flat();
  const allSections = [firstSection, ...restSections];
  const maxWidth = `max-w-[${Math.floor(100 / allSections.length)}%]`;

  return (
    <>
      <ScreenTitle
        subtitle={subtitle}
        title={`${agent.title}`}
        isSticky
        icon={getAgentIcon(agent.resourceTypeId as any)}
      />
      <nav
        data-component="navTabs"
        className={classNames('flex w-full flex-wrap items-center space-x-px')}
      >
        {allSections.map((section, index) => {
          return (
            <Button
              key={`section-navigate-${section.title}`}
              className={classNames(
                'flex-1',
                'text-xs lg:text-base',
                '!px-0.5 lg:!px-1 2xl:!px-2',
                // maxWidth,
                'max-w-[16.5%]',
                index.toString() === active
                  ? 'shadow-2xl'
                  : 'bg-app-panel/50 hover:bg-menu-active/70 hover:text-menu-active-text',
              )}
              buttonType={index.toString() === active ? 'primary' : 'basic'}
              label={section.title}
              // hasIconBefore
              // buttonIcon={
              //   <div
              //     className={classNames(
              //       'flex items-center justify-center',
              //       'w-6 h-6 xl:w-8 xl:h-8',
              //       'ltr:ml-3 rtl:mr-3 ltr:mr-1 rtl:ml-1',
              //       'text-xs font-bold',
              //       'rounded-full',
              //       index.toString() === active
              //         ? 'bg-app-panel-dark text-menu-active'
              //         : 'bg-menu-active text-menu-active-text',
              //     )}
              //   >
              //   </div>
              // }
              onPress={() => {
                sectionsRef.current[index]?.scrollIntoView({
                  behavior: 'smooth',
                  //block: 'center',
                });
              }}
            />
          );
        })}
      </nav>

      <Container col hasOverflowY>
        {allSections.map((section, index) => {
          return (
            <section
              className={classNames('mt-0.5')} //IMPORTANT: This margin needs for proper intersection detection works
              key={`section-wrapper-${section.title}`}
              ref={el => (sectionsRef.current[index] = el)}
            >
              <ContainerViewport
                onEnterViewport={() => {
                  //console.log('Enter', index, visibleSections);
                  setVisibleSection({ ...visibleSections, [index]: true });
                }}
                onLeaveViewport={() => {
                  //console.log('Exit', index, visibleSections);
                  setVisibleSection(_.omit(visibleSections, index));
                }}
              >
                {section.content}
              </ContainerViewport>
            </section>
          );
        })}

        <section className={classNames('px-1')}>
          <TitleSection
            key={`resource-cost_of_ownership-editor-section`}
            id={`resource-cost_of_ownership-editor-section`}
            title={t`Cost of Ownership`}
            inTabView
            hasPadding
            hasScreenTitle
            titleView
            collapsible
            className={classNames('top-20', 'text-lg', 'border-opacity-50')}
          >
            <InputGroupParam
              title={t`Equipment Costs`}
              description={t`What is the initial equipment cost, how much is the monthly maintenance, those settings, will help us calculate the ROI.`}
            >
              <InputGroupList
                hasPadding
                panelMode
                className={classNames(
                  'divide-menu/50 space-y-4 divide-y divide-solid',
                )}
              >
                <InputNumber value={0} title={t`Initial Cost (Investment)`} />
                <InputNumber value={0} title={t`Monthly Cost (Maintenance)`} />
              </InputGroupList>
            </InputGroupParam>
          </TitleSection>
        </section>
      </Container>
    </>
  );
};
export default ResourcePolicyAgentView;
