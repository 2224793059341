import { useLoadDatasetObjectsQuery } from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { cn } from '../../common/utils';
import ErrorIndicator from '../../components/ErrorIndicator';
import LoadingIndicator from '../../components/LoadingIndicator';
import DropdownSelector from '../../components/actions/DropdownSelector';
import { ImportDatasetButton } from '../../components/importer/DatasetActionHelpers';
import { ScreenTitle } from '../../components/layout/ScreenTitle';
import { getDataTypeConfig } from '../../datasetObject/store/dataItemObject';
import {
  ImportType,
  ImportTypeToJobTypeMap,
} from '../../import/store/import.types';
import ImportPipelineFileUploaderByType from '../../importJobs/ImportPipelineFileUploaderByType';
import { warehouseSelectedId } from '../../store/warehouse.state';

interface DataSettingSelectorProps {
  selectedId: string;
  dataType: ImportType;
  isLoading?: boolean;
  viewMode?: 'header' | 'table' | 'dashboard' | 'panel' | 'minimal';
  onChange: (id: string) => void;
}

const DataSettingSelector: FC<DataSettingSelectorProps> = ({
  selectedId,
  dataType,
  viewMode,
  onChange,
}) => {
  const { t } = useTranslation('dataset');
  const whId = useRecoilValue(warehouseSelectedId);

  const dataTypeConfig = getDataTypeConfig(dataType);

  const { data, loading, error } = useLoadDatasetObjectsQuery({
    variables: {
      warehouseId: whId,
      filter: {
        objectType: [ImportTypeToJobTypeMap[dataType]],
      },
    },
  });

  const dataMap = _.keyBy(
    data?.warehouse?.datasetObjects?.content,
    ds => ds.id,
  );

  return (
    <div data-component={`DataSettingSelector-${_.startCase(dataType)}`}>
      {viewMode !== 'minimal' && (
        <ScreenTitle
          title={dataTypeConfig.title}
          subtitle={t(dataTypeConfig.subtitle)}
          icon={dataTypeConfig.icon}
          isSticky
          className="top-8 px-2"
        />
      )}

      {loading && (
        <LoadingIndicator
          oneLiner
          message={t('Loading {{dataType}}', {
            dataType: dataTypeConfig.title,
          })}
        />
      )}
      {error && (
        <ErrorIndicator
          message={t('Error Loading {{dataType}}', {
            dataType: dataTypeConfig.title,
          })}
          selfCenter
        />
      )}

      {!loading && !error && (
        <div
          className={cn(
            'group',
            'flex flex-col items-center',
            viewMode === 'minimal' ? '' : 'bg-sidebar-title/75 px-2 py-4',
          )}
        >
          {_.isEmpty(dataMap) ? (
            <ImportDatasetButton dataType={dataType} />
          ) : (
            <>
              <DropdownSelector
                label={viewMode === 'minimal' ? 'Target Dataset' : null}
                className="mb-2 w-full text-sm"
                value={selectedId || t('No Data Selected')}
                values={_.keys(dataMap)}
                renderValue={(id: string) => dataMap[id]?.title || id}
                valueHelper={(id: string) =>
                  dataMap[id]?.updatedAt
                    ? new Date(dataMap[id]?.updatedAt).toLocaleString('en-GB')
                    : ''
                }
                onChange={onChange}
                panelModeMultiline
                panelMode
                DropAlignRight
                widthFull
                valuePrimary
                multiline
                light
                hasSearch
                hasSearchLabel={t(`Select ${dataType}`)}
              />
              {viewMode !== 'minimal' && (
                <ImportPipelineFileUploaderByType
                  dataType={dataType}
                  viewMode={viewMode}
                  datasetId={selectedId}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DataSettingSelector;
