import { OptimizationRunStatus } from '@warebee/frontend/data-access-api-graphql';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import AllocationPreview from '../allocation/AllocationPreview';
import AnalyzedProductsTable from '../analyze/AnalyzedProductsTable';
import AnalyzeDashboard from '../analyze/dashboard/AnalyzeDashboard';
import AssignmentPolicyProductByRuleTableView from '../assignmentPolicy/AssignmentPolicyProductByRuleTableView';
import SwitchLayoutTable from '../components/actions/SwitchLayoutTable';
import { HeaderAppMenu } from '../components/headers/HeaderAppMenu';
import { Container } from '../components/layout/ContainerFlex';
import { Spacer } from '../components/layout/Spacer';
import LayoutActiveFeatureTag from '../layout/LayoutActiveFeatureTag';
import NavigationModeToolbar from '../layout/toolbar/NavigationModeToolbar';
import { viewerSelectedLayout } from '../layout/viewer/store/viewer.state';
import OrderLinesByRuleTable from '../pickingPolicy/OrderLinesByRuleTable';
import ResourcePolicyAgentViewContainer from '../resourcePolicy/agent/ResourcePolicyAgentViewContainer';
import OptimisationLayoutView from './OptimisationLayoutView';
import SimulationFilters from './SimulationFilters';
import SimulationLayoutView from './SimulationLayoutView';
import SimulationHelperGettingStarted from './helper/SimulationHelperGettingStarted';
import ReassignJobsView from './stepContentViews/ReassignJobsView';
import SimulationAllocationView from './stepContentViews/SimulationAllocationView';
import SimulationComplianceView from './stepContentViews/SimulationComplianceView';
import SimulationDatePicker from './stepContentViews/SimulationDatePicker';
import SimulationForecastSwitch from './stepContentViews/SimulationForecastSwitch';
import SimulationIssuesView from './stepContentViews/SimulationIssuesView';
import SimulationOptimisationSetupView from './stepContentViews/SimulationOptimisationSetupView';
import { allocationAnalyzeId } from './store/allocation/allocation.state';
import {
  analyzeContentViewAs,
  itemsContentViewAs,
} from './store/analyze.state';
import { assignmentPolicySelectedIdentity } from './store/assignmentPolicy.state';
import {
  optimisationAnalyzeId,
  optimisationStatus,
  optimisationViewMode,
} from './store/optimisation.state';
import { pickingPolicySelectedDetailsIdentityAtom } from './store/pickingPolicy/pickingPolicy.state';
import {
  simulationAnalyzeId,
  simulationIsEditable,
  simulationWithAllocation,
} from './store/simulation.state';
import { OptimisationViewMode } from './store/simulation.types';
import {
  simulationAnalyseTabKey,
  simulationPolicyTabKey,
  simulationWizardSelectedStep,
} from './store/simulation.wizard.state';
import { workforceSelectedAgentId } from './store/workforce.state';
import SimulationHeatmapToolbar from './toolbars/SimulationHeatmapToolbar';
import SimulationViewModeToolbar from './toolbars/SimulationViewModeToolbar';

const SimulationMainContentView: React.FC = () => {
  const { t } = useTranslation('simulation');
  const [assignmentPolicySelection, setAssignmentPolicySelection] =
    useRecoilState(assignmentPolicySelectedIdentity);

  const step = useRecoilValue(simulationWizardSelectedStep);
  const layout = useRecoilValue(viewerSelectedLayout);
  const viewMode = useRecoilValue(optimisationViewMode);
  const canEdit = useRecoilValue(simulationIsEditable);
  const optStatus = useRecoilValue(optimisationStatus);

  const [pickingDetails, setPickingDetails] = useRecoilState(
    pickingPolicySelectedDetailsIdentityAtom,
  );
  const [analyzeViewAs, setAnalyzeViewAs] =
    useRecoilState(analyzeContentViewAs);

  const analyzeId = useRecoilValue(simulationAnalyzeId);
  const optimisationAnlId = useRecoilValue(optimisationAnalyzeId);
  const allocationAnlId = useRecoilValue(allocationAnalyzeId);
  const selectedAgentId = useRecoilValue(workforceSelectedAgentId);

  const analyzeTabSelected = useRecoilValue(simulationAnalyseTabKey);
  const policyTabSelected = useRecoilValue(simulationPolicyTabKey);
  const analyzeItemsTabSelected = useRecoilValue(itemsContentViewAs);
  const simulationWithAllocate = useRecoilValue(simulationWithAllocation);

  const showAnalyzedTableSwitch =
    step?.id === 'analyse' || step?.id === 'optimise';

  const showProductItems =
    assignmentPolicySelection?.filterType === 'items' &&
    step.id === 'policy-storage';

  const showResourcePolicy = step.id === 'policy-resources';
  const showAllocatePreview =
    step?.id === 'analyse' && _.isNil(analyzeId) && simulationWithAllocate;

  const layoutTitle = layout?.title || '';
  const isOptimisationStep = step.id === 'optimise';

  const hasAnalyseLayout =
    step?.id !== 'compliance' &&
    (!isOptimisationStep || viewMode !== OptimisationViewMode.Optimised);

  const hasOptimiseLayout =
    optStatus === OptimizationRunStatus.COMPLETED &&
    isOptimisationStep &&
    (viewMode === OptimisationViewMode.Optimised ||
      viewMode === OptimisationViewMode.Compare);

  const showOptimisationSetup =
    isOptimisationStep &&
    optStatus !== OptimizationRunStatus.COMPLETED &&
    optStatus !== OptimizationRunStatus.STOPPING &&
    optStatus !== OptimizationRunStatus.IN_PROGRESS;

  const showPickingPolicyOrderLinesTable =
    step?.id === 'policy-picking' &&
    (pickingDetails?.showOrderLinesByRule ||
      pickingDetails?.showOrderLinesUnmatched);

  //
  // ---------------- Handle special content view ----------------
  //

  if (
    step?.id === 'analyse' &&
    analyzeTabSelected === 'tab-analyze-items' &&
    analyzeItemsTabSelected === 'allocate'
  ) {
    return (
      <ReassignJobsView
        title={t`Allocate`}
        analyzeId={allocationAnlId}
        hasJobFilterOptions={false}
      />
    );
  }

  if (
    step?.id === 'policy-picking' &&
    policyTabSelected === 'tab-policy-allocate'
  ) {
    return <SimulationAllocationView />;
  }

  switch (step?.id) {
    case 'getting-started':
      return (
        <>
          <HeaderAppMenu
            hasControls={false}
            title={t`Getting Started with Warehouse Simulation`}
          />
          <SimulationHelperGettingStarted />
        </>
      );
    case 'data-issues':
    case 'import-orders':
    case 'import-assignments':
    case 'import-layout':
    case 'import-items':
      return <SimulationIssuesView />;

    case 'allocation-requirement':
      return <SimulationAllocationView />;

    case 'compliance':
      // return (
      //   <Suspense>
      //     <SimulationPicklistStats />
      //   </Suspense>
      // );
      return <SimulationComplianceView />;

    case 'optimise':
      if (showOptimisationSetup) {
        return <SimulationOptimisationSetupView />;
      }
      break;

    case 'optimise-reassign':
      return (
        <ReassignJobsView
          title={t`Re-assign`}
          analyzeId={optimisationAnlId}
          hasJobFilterOptions={true}
        />
      );
  }

  if (showAllocatePreview) {
    return (
      <>
        <HeaderAppMenu hasControls={false} title={t`Allocation Preview`} />
        <Container col>
          <AllocationPreview />
        </Container>
      </>
    );
  }
  if (showResourcePolicy) {
    return (
      <>
        <HeaderAppMenu hasControls={false} title={t`Agent details`} />
        <ResourcePolicyAgentViewContainer />
      </>
    );
  }

  if (showProductItems) {
    return (
      <AssignmentPolicyProductByRuleTableView
        panelHeader={{
          title: t`Items`,
          onCloseClick: () => setAssignmentPolicySelection(null),
          closable: true,
        }}
      />
    );
  }

  if (showPickingPolicyOrderLinesTable) {
    return (
      <>
        <HeaderAppMenu
          title={t`Order lines`}
          closable
          onCloseClick={() => setPickingDetails(null)}
        />
        <OrderLinesByRuleTable />
      </>
    );
  }

  //analyze single
  if (hasAnalyseLayout && !hasOptimiseLayout) {
    return (
      <>
        <HeaderAppMenu
          // title={analyzeViewAs === 'dashboard' ? 'KPIs' : ''}
          subtitle={isOptimisationStep && t`(Before)`}
        >
          {analyzeViewAs === 'dashboard' && selectedAgentId && (
            <>
              <SimulationDatePicker />
              <SimulationForecastSwitch />
            </>
          )}

          <Spacer flexspace />
          <LayoutActiveFeatureTag />
          <SimulationFilters />
          {showAnalyzedTableSwitch && (
            <SwitchLayoutTable
              selected={analyzeViewAs}
              onChange={v => setAnalyzeViewAs(v)}
            />
          )}
        </HeaderAppMenu>
        {(analyzeViewAs === 'layout' || !showAnalyzedTableSwitch) && (
          <Container
            componentName={`SimulationLayoutViewContainer`}
            overflow-hidden
            col
            flex1
            className={classNames('relative')}
          >
            <SimulationLayoutView stepId={step.id} />
            {step.id === 'policy-routing' && <NavigationModeToolbar />}
            <SimulationHeatmapToolbar />
          </Container>
        )}

        {analyzeViewAs === 'table' && showAnalyzedTableSwitch && (
          <AnalyzedProductsTable analyzeId={analyzeId} />
        )}
        {analyzeViewAs === 'dashboard' && showAnalyzedTableSwitch && (
          <AnalyzeDashboard analyzeId={analyzeId} />
        )}
      </>
    );
  }

  //-- optimise single
  if (hasOptimiseLayout && !hasAnalyseLayout) {
    return (
      <>
        <HeaderAppMenu title={t`Optimised`} subtitle={t`(After)`}>
          <Spacer flexspace />
          <LayoutActiveFeatureTag />
          <SimulationFilters />
          {showAnalyzedTableSwitch && (
            <SwitchLayoutTable
              selected={analyzeViewAs}
              onChange={v => setAnalyzeViewAs(v)}
            />
          )}
        </HeaderAppMenu>
        {(analyzeViewAs === 'layout' || !showAnalyzedTableSwitch) && (
          <Container
            col
            overflow-hidden
            componentName={`SimulationLayoutViewContainer`}
            className={classNames('relative flex-1')}
          >
            <OptimisationLayoutView />
            <SimulationViewModeToolbar />
            <SimulationHeatmapToolbar />
          </Container>
        )}

        {analyzeViewAs === 'table' && showAnalyzedTableSwitch && (
          <AnalyzedProductsTable analyzeId={optimisationAnlId} />
        )}
        {analyzeViewAs === 'dashboard' && showAnalyzedTableSwitch && (
          <AnalyzeDashboard analyzeId={optimisationAnlId} />
          // <AnalyzeCompareDashboard
          //   optimiseAnalyzeId={optimisationAnlId}
          //   analyzeId={analyzeId}
          // />
        )}
      </>
    );
  }
  // default  - analyze compare
  return (
    <>
      <Container overflow className={classNames('relative !h-auto')}>
        <HeaderAppMenu
          title={t`Optimisation`}
          subtitle={t`(Before)`}
          className={classNames('flex-1')}
        >
          {/* <LayoutActiveFeatureTag /> */}
          <Spacer flexspace />
          <SimulationFilters />
        </HeaderAppMenu>

        <HeaderAppMenu
          subtitle={t`(After)`}
          className={classNames(
            'border-app-panel-dark flex-1 ltr:border-l rtl:border-r',
          )}
        >
          <Spacer flexspace />
          <LayoutActiveFeatureTag />
          <SimulationFilters />
        </HeaderAppMenu>
      </Container>
      <Container
        overflow
        flex1
        className={classNames('relative', 'border-app-background/70 border-8')}
      >
        <Container
          col
          overflow-hidden
          componentName={`SimulationLayoutViewContainer`}
          className={classNames(
            'relative',
            'flex-1',
            'border-app-panel-dark ltr:border-l rtl:border-r-4',
          )}
        >
          <SimulationLayoutView stepId={step.id} hideViewModeToolbar={true} />
        </Container>

        <Container
          col
          overflow-hidden
          componentName={`OptimisationLayoutViewContainer`}
          className={classNames(
            'relative',
            'flex-1',
            'border-app-panel-dark ltr:border-l rtl:border-r-4',
          )}
        >
          <OptimisationLayoutView />
        </Container>
        <SimulationViewModeToolbar />
        <SimulationHeatmapToolbar />
      </Container>
    </>
  );
};

export default SimulationMainContentView;
