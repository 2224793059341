import {
  LayoutLocationsSummaryFragment,
  LayoutLocationsSummaryGroupBy,
  SimulationItemDataFragment,
} from '@warebee/frontend/data-access-api-graphql';
import { LocationsStatsDataColumn } from '@warebee/shared/export-converter';
import _ from 'lodash';
import { atom, selectorFamily } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus } from '../../common/types';
import { LocationsStatsDimensionId } from '../tableViews/locationsStatsTable.config';
import { PolicyFilterData, PolicyFilteredItems } from './policyFilter.types';

const getKey = (postfix: string) =>
  `warego-simulation-policy-filters-${postfix}`;

export const policyFiltersSearchValues = atom<Record<string, string>>({
  key: getKey('search-values'),
  default: {},
});

export const policyFilterDataAtom = atom<Record<string, PolicyFilterData>>({
  key: getKey('filter-values-all-atom'),
  default: {},
});

export const policyFilterData = selectorFamily<PolicyFilterData, string>({
  key: getKey('filter-values-all'),
  get:
    filterKey =>
    ({ get }) =>
      get(policyFilterDataAtom)?.[filterKey] ?? null,
  set:
    (filterKey: string) =>
    ({ get, set }, value) => {
      if (_.isEmpty(filterKey)) {
        console.error('Filter key is not provided');
        return;
      }
      const current = get(policyFilterDataAtom);
      set(policyFilterDataAtom, { ...current, [filterKey]: value });
    },
});

export const policyFilterSearchByFilterKey = selectorFamily<string, string>({
  key: getKey('search-by-filter'),
  get:
    (key: string) =>
    ({ get }) =>
      get(policyFiltersSearchValues)[key],
});

export const policyFilteredProducts = atom<
  PolicyFilteredItems<SimulationItemDataFragment>
>({
  key: getKey('filtered-simulation-products'),
  default: null,
});

export const policyFilteredProductsSearchValues = atom<Record<string, string>>({
  key: getKey('filtered-simulation-products-search-values'),
  default: {},
});

export const policyLocationsStatsDimension =
  persistAtom<LocationsStatsDimensionId>({
    key: getKey('locations-stats-dimension'),
    default: LayoutLocationsSummaryGroupBy.LOCATION_RACKING_TYPE,
  });

export const policyFilteredLocationStats = atom<
  LayoutLocationsSummaryFragment[]
>({
  key: getKey('locations-stats-by-rule'),
  default: null,
});

export const policyFilteredLocationsStatsSearchValues = atom<
  Partial<Record<LocationsStatsDataColumn, any>>
>({
  key: getKey('locations-stats-by-rule-search-values'),
  default: {},
});

export const policyFilteredLocationsStatsLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('locations-stats-by-rule-load-status'),
  default: AsyncLoadStatus.None,
});
