import classNames from 'classnames';
import _ from 'lodash';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../../common/types';
import LoadingIndicator from '../../components/LoadingIndicator';
import { Alert } from '../../components/alerts/Alerts';
import { HeaderAppMenu } from '../../components/headers/HeaderAppMenu';
import { ToolbarImportParams } from '../../components/importer/toolbars/ToolbarImportParams';
import InputCheckbox from '../../components/inputs/InputCheckbox';
import { Container } from '../../components/layout/ContainerFlex';
import { Spacer } from '../../components/layout/Spacer';
import ImporterFileSelector from '../csv/components/ImporterFileSelector';
import ImporterJSONPreview from '../csv/components/ImporterJSONPreview';
import ImporterSelectedFileHeader from '../csv/components/ImporterSelectedFileHeader';
import { getImportConfiguration } from '../store/import.default';
import {
  importMappingSettingsByType,
  importRawPreview,
  importRawPreviewStatus,
} from '../store/import.state';
import { ImportStepContainerProps } from '../store/import.types';
import useApplyMappingSettings from '../useApplyMappingSettings';
import useUploadPreviewFile from '../useUploadPreviewFile';

const ImportSelectFileStep: React.FC<ImportStepContainerProps> = props => {
  const { t } = useTranslation('importer');
  const previewData = useRecoilValue(importRawPreview);
  const status = useRecoilValue(importRawPreviewStatus);
  const mappingSettings = useRecoilValue(
    importMappingSettingsByType(props.type),
  );
  const applyMappingSettings = useApplyMappingSettings();
  const [showParseOptions, setShowParseOptions] = useState(true);

  const config = getImportConfiguration(props.type, t);

  const isLoading = status === AsyncLoadStatus.Loading;
  const hasError =
    !_.isEmpty(previewData?.errors) || status === AsyncLoadStatus.Error;
  const isParsed = !_.isNil(previewData) && !hasError;

  const [call, cancel, errorDetails] = useUploadPreviewFile();
  if (!_.isNil(errorDetails)) {
    console.error('UploadPreviewFile errorDetails::', errorDetails);
  }

  return (
    <Container
      col
      componentName="ImportSelectFileStep"
      overflow
      className={classNames(
        'flex-1',
        'border-app-panel-dark ltr:border-l rtl:border-r',
        'relative',
        'bg-app-panel-dark',
      )}
    >
      <HeaderAppMenu
        title={
          isLoading ? (
            t`Loading File...`
          ) : isParsed ? (
            <ImporterSelectedFileHeader />
          ) : (
            t`Select data file`
          )
        }
      />

      <Container
        col
        className={classNames(
          'flex-1 items-center',
          'w-full transition duration-500',
          'bg-sidebar-menu/60',
          'overflow-none',
        )}
      >
        <Suspense
          fallback={<LoadingIndicator selfCenter message={t`Loading...`} />}
        >
          <Container col className={classNames('h-full w-full flex-1')}>
            {((!isParsed && !isLoading) || hasError) && (
              <>
                <header
                  data-component="import-options"
                  className={classNames(
                    'flex items-center',
                    'px-4 py-4',
                    'bg-sidebar-title/75',
                  )}
                >
                  <InputCheckbox
                    label={t`Has header row?`}
                    isSelected={mappingSettings?.hasHeaders ?? true}
                    onChange={hasHeaders =>
                      applyMappingSettings({
                        skipValidation: true,
                        mappingSettings: {
                          ...mappingSettings,
                          hasHeaders,
                        },
                      })
                    }
                    size="xs"
                  >
                    {t`Has header row`}
                  </InputCheckbox>

                  <Spacer flexspace />

                  <button
                    className={classNames(
                      'mx-1',
                      'border-b-2 border-double border-transparent',
                      'cursor-pointer select-none',
                      'border-menu-active',
                      'hover:border-current',
                      'text-menu-text',
                    )}
                    onClick={e => setShowParseOptions(!showParseOptions)}
                  >
                    {t`Import options`}
                  </button>
                </header>
                {showParseOptions && (
                  <ToolbarImportParams
                    settings={mappingSettings?.csvOptions}
                    onChange={csvOptions =>
                      applyMappingSettings({
                        skipValidation: true,
                        mappingSettings: {
                          ...mappingSettings,
                          csvOptions,
                        },
                      })
                    }
                  />
                )}
                <ImporterFileSelector
                  actionName={config.actionName}
                  filename={config.filenameTemplate}
                />
              </>
            )}
            {isLoading && (
              <LoadingIndicator message={t`Loading File`} selfCenter />
            )}
            {isParsed && !isLoading && (
              <>
                <ImporterJSONPreview
                  id={'source'}
                  data={previewData.data}
                  collapsible={false}
                  columns={previewData.fields}
                />
              </>
            )}
          </Container>

          {hasError && (
            <div
              className={classNames(
                'w-full',
                'px-4 py-2',
                'bg-sidebar-menu/60',
              )}
            >
              {status === AsyncLoadStatus.Error && (
                <Alert
                  hasStatusError
                  message={t(
                    'Cant upload/pre-process selected file. {{error}}',
                    { error: errorDetails },
                  )}
                />
              )}
              {_.map(previewData?.errors, (e, i) => (
                <Alert
                  hasStatusError
                  key={i}
                  message={e}
                  // message={`${e.message} (row ${e.row}, index ${i})`}
                />
              ))}
            </div>
          )}
        </Suspense>
      </Container>
    </Container>
  );
};

export default ImportSelectFileStep;
