import { AnalyzeActualityJobEngine } from '@warebee/frontend/data-access-api-graphql';
import { addMilliseconds, isEqual } from 'date-fns';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { warehouseSelectedId } from '../../store/warehouse.state';
import { actualityExtraSettings } from '../store/actuality.state';
import { getFeedDateRangeQuery } from '../store/datasetQueries/feedDateRange';
import { executeDatasetQuery } from '../store/feed.helper';
import {
  feedDatasetId,
  feedDateRange,
  feedDateRangeAtom,
  feedEffectiveDateRange,
  feedSelectedDateRange,
} from '../store/feed.state';
import useAnalyzeActuality from './useAnalyzeActuality';

function useActualityTimeWatcher() {
  const datasetId = useRecoilValue(feedDatasetId);
  const warehouseId = useRecoilValue(warehouseSelectedId);
  const feedRange = useRecoilValue(feedDateRange);
  const selectedRange = useRecoilValue(feedEffectiveDateRange);
  const setSelectedRange = useSetRecoilState(feedSelectedDateRange);
  const setDateRangeAtom = useSetRecoilState(feedDateRangeAtom);
  const actualitySettings = useRecoilValue(actualityExtraSettings);
  const refreshInterval = actualitySettings?.refreshInterval ?? 5;
  const [startAnalyze] = useAnalyzeActuality();

  const duration =
    selectedRange?.[1]?.getTime() - selectedRange?.[0]?.getTime();
  const shouldWatch = isEqual(selectedRange?.[1], feedRange?.[1]);

  // console.log('useActualityTimeWatcher: Initial render', {
  //   datasetId,
  //   warehouseId,
  //   feedRange,
  //   selectedRange,
  //   refreshInterval,
  //   duration,
  //   shouldWatch,
  // });

  async function fetchDateRange() {
    try {
      // console.log('Fetching date range...');
      const compiledQuery = getFeedDateRangeQuery(datasetId).compile();
      const result = await executeDatasetQuery({
        warehouseId,
        compiledQuery,
        comment: '[feed] Date Range',
      });
      // console.log('Fetched date range:', result);
      return [result[0].minTime, result[0].maxTime];
    } catch (ex) {
      console.error('FetchDateRange exception:', ex);
    }
  }

  useEffect(() => {
    // console.log('useEffect triggered. shouldWatch:', shouldWatch);
    let timeoutId;
    if (shouldWatch) {
      // console.log('Setting up interval...');
      timeoutId = setInterval(
        async () => {
          // console.log('Interval triggered, fetching date range...');
          const [from, to] = await fetchDateRange();
          // console.log('New date range:', { from, to });
          // console.log('Current feedRange:', feedRange);
          if (!isEqual(to, feedRange?.[1])) {
            // console.log('Date range changed, updating state...');
            setDateRangeAtom([from, to]);
            setSelectedRange([addMilliseconds(to, -duration), to]);
            if (actualitySettings?.runAnalyseForNewEvents) {
              // console.log('Starting analyze for new events...');
              startAnalyze({
                newEventsOnly: true,
                engine: AnalyzeActualityJobEngine.LAMBDA,
              });
            }
          } else {
            // console.log('No change in date range');
          }
        },
        1000 * 60 * refreshInterval,
      );
    }

    return () => {
      // console.log('Cleanup: Cancelling event time watcher');
      clearTimeout(timeoutId);
    };
  }, [shouldWatch, feedRange, refreshInterval, duration, actualitySettings]);

  return null;
}

export default useActualityTimeWatcher;
