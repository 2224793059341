import _ from 'lodash';
import React, {
  HTMLAttributes,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { analyticsAgent } from '../../AnalyticTracker';
import { cn } from '../../common/utils';
import LoadingIndicator from '../LoadingIndicator';
import * as Icon from '../icons';
import MenuDotsVertical from '../icons/MenuDotsVertical';
import { InputSearch } from '../inputs/InputSearch';
import Dropdown, { DropdownVisualProps } from './Dropdown';

const statusType = {
  ALERT: '',
  ERROR: '',
  WARNING: '',
  INFO: '',
  OKAY: '',
};

export type DropdownSelectorProps<T> = {
  labelHelper?: React.ReactNode;
  label?: React.ReactNode;
  value?: T;
  valueHelper?: string | ((value: T) => string);
  values: Readonly<T[]>;
  icons?: T[];
  subAction?: string;
  classNameItem?: string;
  classNameValue?: string;
  classNameLabel?: string;
  hasSearchLabel?: string;
  policyMode?: boolean;
  panelMode?: boolean;
  panelModeMultiline?: boolean;
  flexMode?: boolean;
  formMode?: boolean;
  tableMode?: boolean;
  hasShadow?: boolean;
  statusType?: typeof statusType | string;
  labelVertical?: boolean;
  isSelected?: boolean;
  active?: boolean;
  panel?: boolean;
  border?: boolean;
  icon?: React.ReactNode;
  iconClass?: string;
  iconMenu?: React.FC<HTMLAttributes<Element>>;
  iconValue?: React.FC<HTMLAttributes<Element>>;
  iconSelection?: React.FC<HTMLAttributes<Element>>;
  iconSelectionClass?: string;
  onChange?: (value: T, e?: React.MouseEvent) => void;
  onSubActionClick?: MouseEventHandler<HTMLDivElement>;
  renderValue?: (value: T, filterString?: string) => React.ReactNode;
  renderValueSelected?: (value: T, filterString?: string) => React.ReactNode;
  filterValue?: (value: T, filterString: string) => boolean;
  onAsyncSearch?: (v: string) => void;
  hasAsyncSearch?: boolean;
  asyncSearchValue?: string;
  isLoading?: boolean;
} & Omit<
  DropdownVisualProps,
  'label' | 'value' | 'valueHelper' | 'values' | 'onChange'
>;

function filterValueDefaultCallback(value: unknown, filterString: string) {
  if (_.isEmpty(filterString)) {
    return true;
  }

  return value?.toString()?.indexOf(filterString) >= 0;
}

function DropdownSearch<T>({
  label,
  hasSearchLabel,
  setSearchFieldValue,
  onAsyncSearch,
}: {
  label: React.ReactNode;
  hasSearchLabel: string;
  setSearchFieldValue: (v: string) => void;
  onAsyncSearch?: (v: string) => void;
}) {
  return (
    <div
      className={cn(
        'flex-1',
        'sticky top-0',
        'z-menu',
        'bg-app-header/80 saturate-110 backdrop-blur',
      )}
    >
      <InputSearch
        className="focus:outline-none focus:ring-0"
        placeholder={`Search ` + (label || hasSearchLabel || '')}
        onChange={v => {
          setSearchFieldValue(v);
          onAsyncSearch && onAsyncSearch(v);
          try {
            analyticsAgent?.track(`Dropdown [Search]: ${label}`);
          } catch (ex) {
            console.debug('analyticsAgent Error:', ex);
          }
        }}
      />
    </div>
  );
}

export default function DropdownSelector<T>({
  labelHelper,
  className,
  classNameItem,
  classNameLabel,
  classNameValue,
  classNameDropDownMenu,
  label,
  hasSearchLabel,
  labelVertical,
  icon,
  iconClass,
  vertical,
  buttonTransparent,
  value,
  valueHelper,
  values,
  icons,
  iconMenu,
  iconValue,
  iconSelection,
  iconSelectionClass,
  light,
  dark,
  border,
  hasIcon,
  statusType,
  hasStatusAlert,
  hasStatusError,
  hasStatusWarning,
  hasStatusInfo,
  hasStatusOkay,
  hasStatusIcon,
  hasSearch,
  hasAsyncSearch,
  hasSubAction,
  subAction,
  headerMode,
  multiline,
  valuePrimary,
  disabled,
  policyMode,
  panelMode,
  panelModeMultiline,
  flexMode,
  formMode,
  panel,
  tableMode,
  hasShadow,
  isSelected,
  active,
  DropUp,
  renderValue,
  renderValueSelected,
  filterValue,
  onChange,
  onSubActionClick,
  ref,
  onAsyncSearch,
  asyncSearchValue,
  isLoading,
  ...elementProps
}: DropdownSelectorProps<T>) {
  const { t } = useTranslation('app');
  // Value of filter field in DropDown
  const [searchFieldValue, setSearchFieldValue] = useState<string>('');

  useEffect(() => {
    setSearchFieldValue(asyncSearchValue ?? '');
  }, [asyncSearchValue]);

  const filterFunction = _.isFunction(filterValue)
    ? filterValue
    : filterValueDefaultCallback;

  const filteredValues = hasSearch
    ? values.filter(item => filterFunction(item, searchFieldValue))
    : values;

  function renderValueHelper(value: T) {
    const valueHelperString =
      typeof valueHelper === 'function' ? valueHelper(value) : valueHelper;
    if (valueHelperString) {
      return (
        <div
          data-component="DropdownSelectorProps"
          className={cn(
            'opacity-80',
            'mb-0.5 mt-2',
            'text-minimal md:text-xxs lg:text-xs',
            'align-middle',
            'rounded',
            'items-center justify-center',
            {
              'bg-app-panel/10 px-2 py-1 ltr:ml-2 rtl:mr-2': !multiline,
            },
          )}
        >
          {valueHelperString}
        </div>
      );
    } else {
      return null;
    }
  }

  hasStatusIcon = false;

  const IconMenu = iconMenu;
  const IconValue = iconValue;
  const IconSelection = iconSelection;

  const statusIconStyle = 'fill-current w-6 h-6 mx-0.5';

  const styleIconButton = cn(
    'p-1 lg:p-2',
    'bg-menu/10 lg:bg-menu/5',
    headerMode
      ? 'headerMode text-menu-active-text hover:text-menu-active-text/90'
      : 'hover:bg-menu-active hover:text-menu-active-text',
    panelMode
      ? 'panelMode rounded h-full flex items-center'
      : policyMode
        ? 'policyMode rounded h-full flex items-center'
        : 'rounded-full',
  );

  const styleIconButtonIcon = cn(
    'h-5 w-5',
    'fill-current',
    'opacity-60 hover:text-menu-active',
    'bg-menu/5 hover:bg-menu-hover',
    'text-menu-text',
    'p-1 mx-0.5',
    'rounded-full',
    tableMode ? 'p-1' : 'p-1 lg:p-2',
  );

  return (
    <Dropdown
      ref={ref}
      disabled={disabled}
      DropUp={DropUp}
      className={className}
      classNameDropDownMenu={classNameDropDownMenu}
      toggle={() => (
        <div
          className={cn(
            { 'shadow-xl': hasShadow },
            { 'flex-1': !panelMode || !policyMode },
            { 'flex-col': labelVertical },
            { 'bg-app-panel-dark': dark },
            { 'text-menu-text': light },
            { 'bg-app-panel': panel },
            { 'bg-status-okay text-menu-text': statusType === 'OKAY' },
            { 'bg-status-alert text-menu-text': statusType === 'ALERT' },
            { 'bg-status-error': statusType === 'ERROR' },
            { 'bg-status-warning': statusType === 'WARN' },
            { 'bg-status-info': statusType === 'INFO' },
            { 'bg-status-info': hasStatusError },
            { 'bg-opacity-0': buttonTransparent || panelMode || policyMode },
            label && !panelModeMultiline
              ? 'text-menu-text inline-flex w-full items-center justify-center font-medium focus:outline-none focus:ring-0'
              : label && panelModeMultiline
                ? 'flex w-full flex-col items-start'
                : 'flex items-center',
            border
              ? disabled
                ? 'border-menu/70 border'
                : 'border-menu-active/50 border'
              : '',
            { 'rounded-md': !formMode },
            { 'mt-2 ltr:ml-1 rtl:mr-1': tableMode },
            { 'cursor-default': disabled },
            'w-full',
            className,
          )}
        >
          {hasStatusIcon && hasStatusOkay && (
            <Icon.TriangleOk className={statusIconStyle} />
          )}
          {hasStatusIcon ||
            (hasStatusWarning && (
              <Icon.TriangleInfo className={statusIconStyle} />
            ))}
          {hasStatusIcon ||
            (hasStatusAlert && (
              <Icon.TriangleInfo className={statusIconStyle} />
            ))}
          {hasStatusIcon ||
            (hasStatusError && (
              <Icon.TriangleInfo className={statusIconStyle} />
            ))}
          {hasStatusIcon ||
            (hasStatusInfo && <Icon.CircleInfo className={statusIconStyle} />)}
          {icon && (
            <div
              className={cn(
                iconClass
                  ? iconClass
                  : 'bg-menu-button/75 text-menu-active-text hover:bg-menu-active hover:text-menu-active-text/90 m-1 flex h-10 w-10 items-center rounded p-1 shadow ltr:ml-0 ltr:mr-1 rtl:ml-1 rtl:mr-0',
              )}
            >
              {icon}
            </div>
          )}

          {label && (
            <div
              data-component="DropdownValueLabel"
              className={cn(
                { 'w-full': labelVertical || panelModeMultiline },
                { 'text-xs': panelMode || policyMode },
                { 'flex flex-col': labelHelper },
                flexMode || formMode
                  ? 'flex-1 py-2'
                  : 'text-menu-text uppercase',
                panelModeMultiline ? 'pb-2 pt-0.5' : 'px-1 ltr:mr-1 rtl:ml-1',
                disabled
                  ? 'text-menu-disabled-text'
                  : isSelected
                    ? 'text-menu-text'
                    : 'text-menu-active',
                classNameLabel,
              )}
            >
              {label}
              {labelHelper}
            </div>
          )}

          {!vertical && (
            <div
              data-component={`DropdownValueContainer`}
              className={cn(
                'flex flex-1 items-center',
                'truncate',
                { 'bg-menu-active text-menu-active-text': headerMode },
                { 'bg-sidebar-header/80': panelMode },
                { 'bg-app-panel/70': policyMode },
                { 'bg-sidebar-header/90 shadow': light },
                {
                  'border-menu-active/30 any-hover:hover:border-menu-active w-full border':
                    panelModeMultiline,
                },
                { 'rounded-md': !formMode },
                {
                  'focus:border-menu-active focus:text-menu-active w-full border p-3 text-2xl focus:border-opacity-100':
                    formMode,
                },
                {
                  'text-menu-active':
                    (formMode || buttonTransparent) && !headerMode,
                },
                {
                  'border-menu-text/20 text-menu-text/30': formMode && disabled,
                },
                {
                  'placeholder-menu-active hover:text-menu-hover hover:placeholder-brand-secondary border-menu-active/50 hover:border-menu-active':
                    formMode && !disabled,
                },
                // {
                //   'text-menu-text': !disabled && !formMode && !headerMode,
                // },
                classNameValue,
              )}
            >
              <div
                data-component="DropdownValueWrapper"
                className={cn(
                  'flex flex-1 items-start',
                  'min-w-0',
                  'text-ellipsis',
                  multiline ? 'flex-col' : '',
                  formMode ? 'formMode' : 'px-2 py-1',
                )}
              >
                {iconValue ? (
                  <IconValue className={cn('fill-current', iconClass)} />
                ) : (
                  <span
                    data-component={`DropdownValue`}
                    data-label={`DropdownMenuValue-${value || ''}`}
                    aria-label={`DropdownMenuValue-${value || ''}`}
                    className={cn('w-full', 'truncate')}
                  >
                    {renderValueSelected
                      ? renderValueSelected(value, searchFieldValue)
                      : renderValue
                        ? renderValue(value, searchFieldValue)
                        : value?.toString()}
                  </span>
                )}

                {valueHelper && renderValueHelper(value)}
              </div>

              {!disabled && (
                <div className="flex-shrink-0">
                  {iconSelection ? (
                    <IconSelection
                      className={cn(
                        'h-6 w-5',
                        'fill-current',
                        'flex-shrink-0',
                        iconSelectionClass,
                      )}
                    />
                  ) : (
                    <Icon.ChevronDown
                      className={cn('h-6 w-5', 'fill-current', 'flex-shrink-0')}
                    />
                  )}
                </div>
              )}
            </div>
          )}

          {vertical && (
            <span className={cn(styleIconButton)}>
              <MenuDotsVertical
                className={cn(
                  'fill-current',
                  panelMode || policyMode
                    ? 'h-4 w-4'
                    : tableMode
                      ? 'h-4 w-4'
                      : 'h-5 w-5',
                )}
              />
            </span>
          )}
        </div>
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...elementProps}
    >
      {(hasSearch || hasAsyncSearch) && (
        <div
          className={cn(
            'flex-1',
            'sticky top-0',
            'z-menu',
            'bg-sidebar-header/80 saturate-110 backdrop-blur',
          )}
        >
          <InputSearch
            className={cn('focus:outline-none focus:ring-0')}
            value={searchFieldValue}
            placeholder={t`Search ` + (label || hasSearchLabel || '')}
            onChange={v => {
              setSearchFieldValue(v);
              onAsyncSearch && onAsyncSearch(v);
              try {
                analyticsAgent?.track(`Dropdown [Search]: ${label}`);
              } catch (ex) {
                console.debug('analyticsAgent Error:', ex);
              }
            }}
          />
        </div>
      )}
      {filteredValues.map((optionValue, index) => (
        <Dropdown.Item
          key={`dropdown-item-#${index}-${optionValue}`}
          onClick={e => {
            onChange(optionValue, e);
            try {
              analyticsAgent?.track(`Dropdown [Select]: ${label} ${value}`);
            } catch (ex) {
              console.debug('analyticsAgent Error:', ex);
            }
          }}
          active={value === optionValue}
        >
          <div
            className={cn(
              'flex',
              iconMenu ? 'items-center' : 'items-start',
              'w-full',
              'cursor-pointer',
              {
                'flex-col': multiline,
              },
              classNameItem,
            )}
          >
            {iconMenu && (
              <IconMenu
                className={cn(
                  'h-5 w-5',
                  'fill-current',
                  // 'text-menu-text/75',
                  // 'group-hover:text-menu-text',
                  'ltr:mr-1 rtl:ml-1',
                )}
              />
            )}
            <div className={cn('flex-1')}>
              {renderValue
                ? renderValue(optionValue, searchFieldValue)
                : optionValue?.toString()}
            </div>
            {valueHelper && renderValueHelper(optionValue)}
          </div>
        </Dropdown.Item>
      ))}
      {isLoading && <LoadingIndicator inline selfCenter absolute />}
      {_.size(filteredValues) === 0 && (
        <div
          className={cn(
            'm-1 p-2',
            'text-center text-xs',
            'rounded',
            'bg-menu/50 text-menu-text',
          )}
        >
          {t`No items found`}
        </div>
      )}

      {hasSubAction && (
        <div
          className={cn(
            'flex items-center',
            'px-4 py-2',
            'bg-menu-active/50 hover:bg-menu-active/80',
            'text-menu-text hover:hover:text-menu-active-text',
          )}
          onClick={onSubActionClick}
        >
          {subAction}
        </div>
      )}
    </Dropdown>
  );
}
