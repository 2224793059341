import {
  AnalyzeEventFragment,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import _ from 'lodash';
import { atom, selector, selectorFamily } from 'recoil';
import { persistAtom } from '../../common/recoil/persistAtom';
import { AsyncLoadStatus, DataTableState } from '../../common/types';
import { parseJSON } from '../../common/utils';
import { WaypointLabeled } from '../../layout/features/features.types';
import { getEventRoutePart } from '../../simulation/store/simulation.helper';
import { warehouseSelectedId } from '../../store/warehouse.state';
import {
  actualityMainViewType,
  actualitySelectedDocument,
} from './actuality.state';
import {
  ActualityAnalyzedEvents,
  getActualityAnalyzedEventsQuery,
} from './datasetQueries/actualityAnalyzedEvents';
import {
  ActualityAnalyzedJob,
  ActualityAnalyzedJobColumn,
  ActualityAnalyzedJobQueryBuilderParams,
  ActualityAnalyzedJobs,
  getActualityAnalyzedJobsQuery,
} from './datasetQueries/actualityAnalyzedJobs';
import { getActualitySimulatedEventsQuery } from './datasetQueries/actualitySimulatedEvents';
import { ActualityJobTimingMode } from './feed.default';
import { executeDatasetQuery } from './feed.helper';
import { feedQueryBuilderParams, feedSelectedAgentId } from './feed.state';

const getKey = (postfix: string) => `warebee-actuality-jobs-${postfix}`;

export const actualityJobsBuilderParams =
  selector<ActualityAnalyzedJobQueryBuilderParams>({
    key: getKey('jobs-query-builder-params'),
    get: ({ get }) => {
      const actuality = get(actualitySelectedDocument);
      const queryParamsBase = get(feedQueryBuilderParams);
      return {
        ...queryParamsBase,
        actualityId: actuality.id,
      };
    },
  });

export const actualityShowJobsTable = selector<boolean>({
  key: getKey('show-jobs-table'),
  get: ({ get }) => {
    return get(actualityMainViewType) === 'table';
  },
});

export const actualityJobsTableData = atom<ActualityAnalyzedJobs>({
  key: getKey('jobs-table-data'),
  default: [],
});

export const actualityJobsTableTotalCount = atom<number>({
  key: getKey('jobs-table-total-count'),
  default: 0,
});

export const actualityJobsTableDataLoadStatus = atom<AsyncLoadStatus>({
  key: getKey('jobs-table-data-status'),
  default: AsyncLoadStatus.None,
});

export const actualityJobsTableDataState = atom<
  DataTableState<ActualityAnalyzedJobColumn>
>({
  key: getKey('jobs-table-data-state'),
  default: {
    sortValues: {
      jobDate: SortDirection.DESC,
    },
    searchValues: {},
  },
});

export const actualityJobTimingMode = persistAtom<ActualityJobTimingMode>({
  key: getKey('job-timing-mode'),
  default: 'absolute',
});

export const actualityAgentsAnalyzedJobs = selector<ActualityAnalyzedJob[]>({
  key: getKey('agent-jobs'),
  get: async ({ get }) => {
    const warehouseId = get(warehouseSelectedId);
    const actualityBuilderParams = get(actualityJobsBuilderParams);
    const agentId = get(feedSelectedAgentId);
    if (_.isNil(agentId)) return [];

    const compiledQuery = getActualityAnalyzedJobsQuery({
      ...actualityBuilderParams,
      agentId: agentId,
      sortBy: {
        field: 'minEventTime',
        direction: SortDirection.DESC,
      },
    }).compile();

    const result = await executeDatasetQuery({
      warehouseId,
      compiledQuery,
      comment: '[feed] Agent analyzed jobs',
    });
    return result;
  },
});

export const actualityAgentsAnalyzedJobEvents = selectorFamily<
  ActualityAnalyzedEvents,
  string
>({
  key: getKey('agents-analyzed-job-events'),
  get:
    (jobId: string) =>
    async ({ get }) => {
      const warehouseId = get(warehouseSelectedId);
      const actualityBuilderParams = get(actualityJobsBuilderParams);
      const agentId = get(feedSelectedAgentId);
      if (_.isNil(agentId)) return [];

      const compiledQuery = getActualityAnalyzedEventsQuery({
        ...actualityBuilderParams,
        agentId: agentId,
        jobId,
        sortBy: {
          field: 'eventEndTime',
          direction: SortDirection.ASC,
        },
      }).compile();

      const result = await executeDatasetQuery({
        warehouseId,
        compiledQuery,
        comment: `[feed] Agent analyzed job's events`,
      });
      return result;
    },
});

export const actualityAgentsSimulatedJobEvents = selectorFamily<
  AnalyzeEventFragment[],
  string
>({
  key: getKey('agents-analyzed-job-events'),
  get:
    (jobId: string) =>
    async ({ get }) => {
      const warehouseId = get(warehouseSelectedId);
      const actualityBuilderParams = get(actualityJobsBuilderParams);
      const agentId = get(feedSelectedAgentId);
      if (_.isNil(agentId)) return [];

      const compiledQuery = getActualitySimulatedEventsQuery({
        ...actualityBuilderParams,
        agentId: agentId,
        jobId,
        sortBy: {
          field: 'eventDate',
          direction: SortDirection.ASC,
        },
      }).compile();

      const result = await executeDatasetQuery({
        warehouseId,
        compiledQuery,
        comment: `[actuality] Agent simulated job's events`,
      });
      return _.map(result, e => {
        const parsedEvent: AnalyzeEventFragment = {
          ...e,
          agents: parseJSON(e.agents),
          orderIds: [],
          startPosition: parseJSON(e.startPosition),
          endPosition: parseJSON(e.endPosition),
          details: parseJSON(e.details),
        } as AnalyzeEventFragment;
        return parsedEvent;
      });
    },
});

export const actualityJobRoute = selectorFamily<WaypointLabeled[], string>({
  key: getKey('job-routes'),
  get:
    (jobId: string) =>
    ({ get }) => {
      if (_.isNil(jobId)) return null;
      const simulatedEvents = get(actualityAgentsSimulatedJobEvents(jobId));

      return _(simulatedEvents)
        .filter(
          event =>
            event?.eventType === 'TRAVELLING_HORIZONTAL_START' ||
            event?.eventType === 'TRAVELLING_HORIZONTAL_END' ||
            event?.eventType === 'TRAVELLING_HORIZONTAL',
        )
        .map((e, i) => {
          return getEventRoutePart(jobId, e, i === 0);
        })
        .flatten()
        .value();
    },
});
