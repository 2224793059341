import Flatten from '@flatten-js/core';

import { WaypointType } from '@warebee/shared/engine-model';

export class Waypoint {
  type: WaypointType;
  id: string;
  position: Flatten.Point;
}

export class Route {
  waypoints: Waypoint[];
  distance: number;
  unreachableLocations: Set<string>;
  unreachableTerminals: Waypoint[];
}
