import _ from 'lodash';
import { atom } from 'recoil';
import { persistAtom } from '../common/recoil/persistAtom';

const getKey = (postfix: string) => `warebee-global-${postfix}`;

export const importTriggeredBySim = atom<string>({
  key: getKey('import-triggered-by'),
  default: null,
});

export const billingStatusVisibilityState = persistAtom({
  key: 'billingStatusVisibility',
  default: true,
});

export const helperCalloutVisibilityState = persistAtom({
  key: 'helperCalloutVisibility',
  default: true,
});

export const themeIds = ['Proglove', 'Devoca', 'WarebeeLight'];
export type ThemeId = (typeof themeIds)[number];

export const applyThemePreference = (theme: ThemeId) => {
  const root = window.document.documentElement;
  _.map(themeIds, id => root.classList.remove(id));
  !_.isNil(theme) && root.classList.add(theme);
};

export const appThemeSelectedId = persistAtom<ThemeId>({
  key: getKey('theme-id'),
  default: null,
});

export const appIsEmbedded = atom<boolean>({
  key: getKey('is-embedded'),
  default: null,
});

export const analyticsUserState = persistAtom<boolean>({
  key: getKey('state-analytics'),
  default: true,
});

export const chatState = persistAtom<boolean>({
  key: getKey('state-chat'),
  default: true,
});
