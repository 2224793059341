import {
  AllocationRunResultDeallocatedReason,
  AllocationRunResultUnallocatedReason,
} from '@warebee/frontend/data-access-api-graphql';
import { AllocationSummaryDataRow } from '@warebee/shared/export-converter';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { getQualitativeColor } from '../../../common/color.helper';
import { formatInteger } from '../../../common/formatHelper';
import useFormatter from '../../../common/useFormatter';
import { ColumnConfig } from '../../../components/DatasetTable';
import Tag from '../../../helpContext/Tag';
import {
  getAllocationSummaryGroutByOptions,
  getDeallocatedReasonOptions,
  getUnallocatedReasonOptions,
} from '../../store/allocation/allocation.helper';
import {
  AllocationMetricDescriptor,
  AllocationSummaryGroupByType,
} from '../../store/allocation/allocation.types';
import EmptyValueTag from '../../tags/EmptyValueTag';
import ItemTag from '../../tags/ItemTag';
import UomTag from '../../tags/UomTag';

export type AllocationSummaryTableConfigParams = {
  key: AllocationSummaryGroupByType;
  metric: AllocationMetricDescriptor;
};

function renderUoms(uoms: Record<string, number>) {
  if (_.isEmpty(uoms)) {
    return <EmptyValueTag />;
  }
  return (
    <>
      {_.map(uoms, (value, uom) => (
        <UomTag key={uom} uom={uom} value={value} />
      ))}
    </>
  );
}

function renderQtyValue(value: number) {
  if (_.isNil(value) || value == 0) {
    return <EmptyValueTag />;
  }
  return (
    <span className="flex-1 text-right" title={`RAW: ${formatInteger(value)}`}>
      {Number(value) > 1000000 ? '1M+' : formatInteger(Number(value))}
    </span>
  );
}

function renderUnallocatedReasons(
  reasons: AllocationRunResultUnallocatedReason[],
  t: TFunction<'simulation'>,
) {
  const reasonOptions = getUnallocatedReasonOptions(t);

  if (!reasons || reasons.length === 0) {
    return null;
  }

  return (
    <>
      {reasons.map(reason => {
        const reasonOption = reasonOptions.find(option => option.id === reason);
        return (
          <Tag key={reason}>{reasonOption ? reasonOption.title : reason}</Tag>
        );
      })}
    </>
  );
}

function renderDeallocatedReasons(
  reasons: AllocationRunResultDeallocatedReason[],
  t: TFunction<'simulation'>,
) {
  const reasonOptions = getDeallocatedReasonOptions(t);

  if (!reasons || reasons.length === 0) {
    return null;
  }

  return (
    <>
      {reasons.map(reason => {
        const reasonOption = reasonOptions.find(option => option.id === reason);
        return (
          <Tag key={reason}>{reasonOption ? reasonOption.title : reason}</Tag>
        );
      })}
    </>
  );
}

function useAllocationSummaryTableConfig(
  params: AllocationSummaryTableConfigParams,
): ColumnConfig<AllocationSummaryDataRow>[] {
  const { t } = useTranslation('simulation');
  const formatter = useFormatter();

  const keyOptionsAll = getAllocationSummaryGroutByOptions(t);
  const keyOption = keyOptionsAll[params.key];

  const keyFields = new Set(keyOption.keyColumns);
  const keyFieldsAll: ColumnConfig<AllocationSummaryDataRow>[] = [
    {
      field: 'consignee',
      title: t`Consignee`,
      isHeader: true,
      // hasSort: true,
      hasFilter: true,
    },
    {
      field: 'sku',
      title: t`Item (SKU)`,
      isHeader: true,
      hasSort: true,
      hasFilter: true,
      render: (sku: string, row) => (
        <ItemTag title={sku} filters={{ consignee: row['consignee'], sku }} />
      ),
    },
    { field: 'skuGroup', title: t`Item Group`, hasSort: true, hasFilter: true },
    { field: 'subGroup', title: t`Sub-Group`, hasSort: true, hasFilter: false },

    {
      field: 'transportClass',
      title: t`Transport Class`,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'stockCategory',
      title: t`Stock Category`,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'storageClass',
      title: t`Storage Class`,
      hasSort: true,
      hasFilter: true,
    },
    {
      field: 'pollutionClass',
      title: t`Pollution Class`,
      hasSort: true,
      hasFilter: true,
    },
  ];

  const dataFieldsAll: ColumnConfig<AllocationSummaryDataRow>[] = [
    {
      field: 'requiredQty',
      title: t`Ordered (qty)`,
      hasSort: true,
      // hasFilter: true,
      render: renderQtyValue,
    },
    {
      field: 'requiredUOM',
      title: t`Ordered UOM`,
      render: renderUoms,
      hiddenInExport: true,
    },
    {
      field: 'requiredUOMString',
      title: t`Ordered UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'initiallyPickableQty',
      title: t`Stock (Qty)`,
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'initiallyPickableUOM',
      title: t`Stock UOM`,
      render: renderUoms,
    },
    {
      field: 'initiallyPickableUOMString',
      title: t`Stock UOM`,
      hiddenInBrowser: true,
    },

    {
      field: 'initiallyPickableLocationCount',
      title: t`Stock Locations`,
      render: renderQtyValue,
    },

    //toAllocateRequiredUOM
    {
      field: 'toAllocateRequiredQty',
      title: t`Allocate Required (Qty)`,
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'toAllocateRequiredUOM',
      title: t`Allocate Required UOM`,
      render: renderUoms,
    },
    {
      field: 'toAllocateRequiredUOMString',
      title: t`Allocate Required UOM`,
      hiddenInBrowser: true,
    },

    //toReallocateQty
    {
      field: 'toReallocateQty',
      title: t`Re-allocate (Qty)`,
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'toReallocateUOM',
      title: t`Re-allocate UOM`,
      render: renderUoms,
    },
    {
      field: 'toReallocateUOMString',
      title: t`Re-allocate UOM`,
      hiddenInBrowser: true,
    },
    //toAllocate
    {
      field: 'toAllocateQty',
      title: t`Allocate (Qty)`,
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'toAllocateUOM',
      title: t`Allocate UOM`,
      render: renderUoms,
    },
    {
      field: 'toAllocateUOMString',
      title: t`Allocate UOM`,
      hiddenInBrowser: true,
    },

    {
      field: 'allocatedQty',
      title: t`Allocated (Qty)`,
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'allocatedUOM',
      title: t`Allocated UOM`,
      render: renderUoms,
      hiddenInExport: true,
    },
    {
      field: 'allocatedUOMString',
      title: t`Allocated UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'allocatedLocationCount',
      title: t`Allocated Locations`,
      render: renderQtyValue,
    },

    {
      field: 'allocatedSharedQty',
      title: t`Allocated shared (Qty)`,
      hasSort: true,
      render: renderQtyValue,
    },
    {
      field: 'allocatedSharedLocationCount',
      title: t`Shared Locations`,
      render: renderQtyValue,
    },
    {
      field: 'unAllocatedQty',
      title: t`Un-Allocated (Qty)`,
      hasSort: true,
      render: renderQtyValue,
    },

    {
      field: 'unAllocatedUOM',
      title: t`Un-Allocated UOM`,
      render: renderUoms,
      hiddenInExport: true,
    },
    {
      field: 'unAllocatedUOMString',
      title: t`Un-Allocated UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'unAllocatedReasons',
      title: t`Un-Allocated Reasons`,
      render: (v: AllocationRunResultUnallocatedReason[]) =>
        renderUnallocatedReasons(v, t),
      hasFilter: true,
      hiddenInExport: true,
    },
    {
      field: 'unAllocatedReasonsString',
      title: t`Un-Allocated Reasons`,
      hiddenInBrowser: true,
    },

    {
      field: 'deallocatedQty',
      title: t`De-Allocated (Qty)`,
      hasSort: true,
      render: renderQtyValue,
      hiddenInExport: true,
    },
    {
      field: 'deallocatedUOM',
      title: t`De-allocated UOM`,
      render: renderUoms,
      hiddenInExport: true,
    },
    {
      field: 'deallocatedUOMString',
      title: t`De-allocated UOM`,
      hiddenInBrowser: true,
    },
    {
      field: 'deallocatedLocationCount',
      title: t`De-allocated Locations`,
      render: renderQtyValue,
    },
    {
      field: 'deallocatedReasons',
      title: t`De-allocated Reasons`,
      hasFilter: true,
      render: (v: AllocationRunResultDeallocatedReason[]) =>
        renderDeallocatedReasons(v, t),
      hiddenInExport: true,
    },
    {
      field: 'deallocatedReasonsString',
      title: t`De-allocated Reasons`,
      hiddenInBrowser: true,
    },
    {
      field: 'unpickableQty',
      title: t`Unpickable (Qty)`,
      hasSort: true,
      render: renderQtyValue,
    },
  ];

  const visibleColumnsSet = new Set(params.metric.visibleTableColumns);

  const dataFields =
    visibleColumnsSet.size === 0
      ? dataFieldsAll
      : _.filter(dataFieldsAll, f => visibleColumnsSet.has(f.field as any));
  const columnsConfig: ColumnConfig<AllocationSummaryDataRow>[] = [
    {
      field: 'key',
      title: '',
      hiddenInExport: true,
      isHeader: true,
      render: (key, row) => {
        const dimension = keyOption.getMetricValue(row);
        const colors = getQualitativeColor(dimension, 'dimension');
        return (
          <div
            className={classNames('flex h-6 w-1 rounded-sm px-1 py-1')}
            style={{
              backgroundColor: colors[0],
              color: colors[1],
            }}
          />
        );
      },
    },
    ..._.filter(keyFieldsAll, f => keyFields.has(f.field as any)),
    ...dataFields,
  ];

  return columnsConfig;
}

export default useAllocationSummaryTableConfig;
