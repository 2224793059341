import { MappingError } from './mapping';

function mappingErrorGroupKey(error: MappingError): string {
  const { code, property, value, type } = error;
  if (code == 'InvalidBooleanValue' || code == 'InvalidEnumValue') {
    return JSON.stringify([code, property, value]);
  } else if (code == 'BusinessLogicValidationError') {
    return JSON.stringify([code, property, type]);
  } else {
    return JSON.stringify([code, property]);
  }
}

export class MappingErrorGroupBuilder {
  private errorGroupsByKey: Record<string, MappingError> = {};

  addError(error: MappingError) {
    const key = mappingErrorGroupKey(error);
    const errorGroup = this.errorGroupsByKey[key];
    if (errorGroup) {
      errorGroup.count++;
    } else {
      this.errorGroupsByKey[key] = { ...error, count: 1 };
    }
  }

  getErrorGroups(): MappingError[] {
    return Object.values(this.errorGroupsByKey);
  }
}

export function groupErrors(errors: MappingError[]): MappingError[] {
  const builder = new MappingErrorGroupBuilder();
  for (const e of errors) {
    builder.addError(e);
  }
  return builder.getErrorGroups();
}
