import classNames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { ContainerSidebar } from '../components/layout/ContainerSidebar';
import { warehouseSelectedId } from '../store/warehouse.state';
import { useLoadSimulation } from '../warehouse/hooks/useLoadSimulation';
import SimulationHeader from './SimulationHeader';
import SimulationMainView from './SimulationMainView';
import AllocationEditorSidebar from './panels/allocate/AllocationEditorSidebar';
import AllocationResultSidebar from './sidebar/AllocationResultSidebar';
import DatasetStatsLegendSidebar from './sidebar/DatasetStatsLegendSidebar';
import DatasetStatsSidebar from './sidebar/DatasetStatsSidebar';
import LocationSharingPolicyFiltersSidebar from './sidebar/LocationSharingPolicyFiltersSidebar';
import LocationSharingPolicySidebar from './sidebar/LocationSharingPolicySidebar';
import PickingPolicyEditorSidebar from './sidebar/PickingPolicyEditorSidebar';
import PickingPolicySidebar from './sidebar/PickingPolicySidebar';
import RoutingPolicyEditorSidebar from './sidebar/RoutingPolicyEditorSidebar';
import RoutingPolicySidebar from './sidebar/RoutingPolicySidebar';
import SidebarOptimisationResultsItems from './sidebar/SidebarOptimisationResultsItems';
import SidebarOptimisationResultsItemsImplement from './sidebar/SidebarOptimisationResultsItemsImplement';
import SidebarOptimisationResultsSummary from './sidebar/SidebarOptimisationResultsSummary';
import SidebarResourcePolicy from './sidebar/SidebarResourcePolicy';
import SidebarSimulationCommonInfo from './sidebar/SidebarSimulationCommonInfo';
import SidebarSimulationMenuWizard from './sidebar/SidebarSimulationMenuWizard';
import SimulationAllocationSidebar from './sidebar/SimulationAllocationSidebar';
import SimulationIssuesSidebar from './sidebar/SimulationIssuesSidebar';
import SimulationRouteInfoSidebar from './sidebar/SimulationRouteInfoSidebar';
import SimulationSidebarAnalyze from './sidebar/SimulationSidebarAnalyze';
import SimulationSidebarDataIssuesWide from './sidebar/SimulationSidebarDataIssuesWide';
import SimulationSidebarDataset from './sidebar/SimulationSidebarDataset';
import SimulationSidebarDatasetAssignmentPolicy from './sidebar/SimulationSidebarDatasetAssignmentPolicy';
import SimulationSidebarDatasetAssignments from './sidebar/SimulationSidebarDatasetAssignments';
import SimulationSidebarDatasetItems from './sidebar/SimulationSidebarDatasetItems';
import SimulationSidebarDatasetLayout from './sidebar/SimulationSidebarDatasetLayout';
import SimulationSidebarDatasetOrders from './sidebar/SimulationSidebarDatasetOrders';
import SimulationStartSidebar from './sidebar/SimulationStartSidebar';
import StackingPolicyEditorSidebar from './sidebar/StackingPolicyEditorSidebar';
import StackingPolicySidebar from './sidebar/StackingPolicySidebar';
import AssignmentPolicyEditorSidebar from './sidebar/assignmentPolicy/AssignmentPolicyEditorSidebar';
import ComplianceAssignmentSidebar from './sidebar/compliance/ComplianceAssignmentSidebar';
import ComplianceSidebar from './sidebar/compliance/ComplianceSidebar';
import ComplianceSizeSidebar from './sidebar/compliance/ComplianceSizeSidebar';
import ComplianceVolumeSidebar from './sidebar/compliance/ComplianceVolumeSidebar';
import ComplianceWeightSidebar from './sidebar/compliance/ComplianceWeightSidebar';
import SimulationLocationSidebar from './sidebar/locationProperties/SimulationLocationSidebar';
import SwapPolicyEditorSidebar from './sidebar/optimisationPolicy/OptimisationPolicyEditorSidebar';
import WorkforceSidebar from './sidebar/workforce/WorkforceSidebar';
import {
  simulationIsEditable,
  simulationLoadStatus,
} from './store/simulation.state';
import useSimulationTaskProgressWatcher from './watcher/useSimulationTaskProgressWatcher';

export type SimulationRootParams = {
  simulationId: string;
};
const SimulationRoot: React.FC = () => {
  const { simulationId } = useParams<SimulationRootParams>();
  const whId = useRecoilValue(warehouseSelectedId);
  const [loadSimulation, cleanup] = useLoadSimulation();
  const simLoadStatus = useRecoilValue(simulationLoadStatus);
  const canUpdate = useRecoilValue(simulationIsEditable);
  const { t } = useTranslation('simulation');
  useSimulationTaskProgressWatcher();

  useEffect(() => {
    loadSimulation(whId, simulationId);
    return () => {
      cleanup();
    };
  }, [simulationId]);

  if (
    simLoadStatus === AsyncLoadStatus.None ||
    simLoadStatus === AsyncLoadStatus.Loading
  ) {
    return (
      <>
        <SimulationHeader />
        <ContainerMain>
          <LoadingIndicator selfCenter message={t`Loading simulation`} />
        </ContainerMain>
      </>
    );
  }
  return (
    <>
      <SimulationHeader />
      <ContainerMain>
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <ErrorIndicator
              selfCenter
              message={error.message}
              hasReloadButton
              exception={error}
            />
          )}
        >
          <ContainerSidebar side="left">
            <SidebarSimulationMenuWizard />
            {/* Simulation & Analyse Set */}
            <SimulationStartSidebar />
            {/* DATASET */}
            <SimulationSidebarDataset />
            <SimulationSidebarDatasetLayout />
            <SimulationSidebarDatasetOrders />
            <SimulationSidebarDatasetAssignments />
            <SimulationSidebarDatasetItems />

            {/* Resource Policy */}
            <SidebarResourcePolicy />

            {/* Routing Policy */}
            <RoutingPolicySidebar />
            {canUpdate && <RoutingPolicyEditorSidebar />}

            {/* Storage Policy GROUP  */}
            <LocationSharingPolicySidebar />
            <LocationSharingPolicyFiltersSidebar />
            <SimulationSidebarDatasetAssignmentPolicy />
            {canUpdate && <AssignmentPolicyEditorSidebar />}
            <DatasetStatsSidebar />
            <DatasetStatsLegendSidebar />

            <PickingPolicySidebar />
            <PickingPolicyEditorSidebar />

            <SimulationAllocationSidebar />
            <AllocationResultSidebar />
            <StackingPolicySidebar />
            <StackingPolicyEditorSidebar />

            <SimulationSidebarAnalyze />
            {canUpdate && <AllocationEditorSidebar />}
            <WorkforceSidebar />

            {/* Compliance */}
            <ComplianceSidebar />
            <ComplianceWeightSidebar />
            <ComplianceVolumeSidebar />
            <ComplianceSizeSidebar />
            <ComplianceAssignmentSidebar />

            {/* Optimisation Set */}
            <SidebarOptimisationResultsSummary />
            <SidebarOptimisationResultsItems />
            <SidebarOptimisationResultsItemsImplement />
            <SimulationIssuesSidebar />
            <SimulationSidebarDataIssuesWide />
            <SwapPolicyEditorSidebar />

            <SimulationRouteInfoSidebar />
          </ContainerSidebar>

          <Suspense
            fallback={
              <Container hasOverflowY flex1 col>
                <PanelHeader isLoading title={t`Loading Layout...`} />
                <LoadingIndicator
                  className={classNames(
                    'bg-map-area-striped',
                    'border-app-background border-8',
                  )}
                  selfCenter
                  message={t`Loading Layout`}
                />
              </Container>
            }
          >
            <SimulationMainView />
          </Suspense>

          <ContainerSidebar side="right">
            <SimulationLocationSidebar />
            <SidebarSimulationCommonInfo />
          </ContainerSidebar>
        </ErrorBoundary>
      </ContainerMain>
    </>
  );
};

export default SimulationRoot;
