import {
  AnalyzeEventFragment,
  AnalyzePicklistEventsFragment,
  AnalyzePicklistFragment,
  AnalyzeResultOrdersFilter,
  AnalyzeResultOrdersSortBy,
  AnalyzeResultOrdersSortOption,
  AnalyzeResultOrderSummaryFragment,
  AnalyzeResultWaypointFragment,
  AssignmentChangeSequenceAction,
  LoadAnalyzeLocationsDocument,
  LoadAnalyzeLocationsQuery,
  LoadAnalyzeLocationsQueryVariables,
  LoadAnalyzeWaypointsDocument,
  LoadAnalyzeWaypointsQuery,
  LoadAnalyzeWaypointsQueryVariables,
  OptimizationRunStatus,
  PicklistEventType,
  SimulationMetaFragment,
  SortDirection,
  TravellingHorizontalDetailsFragment,
  WaypointType,
} from '@warebee/frontend/data-access-api-graphql';
import { TFunction } from 'i18next';
import _ from 'lodash';
import { HTMLAttributes } from 'react';
import { TwTheme } from '../../../Tw';
import { WORKFORCE_FORECAST_VERSION } from '../../common/constants';
import { FormattedValueWithUnit } from '../../common/formatHelper';
import { Formatter } from '../../common/useFormatter';
import { cloneWithoutTypename } from '../../common/utils';
import * as Icon from '../../components/icons';
import { secureClient } from '../../GraphQLClient';
import {
  PicklistRoute,
  WaypointLabeled,
} from '../../layout/features/features.types';
import { OrderSearchValue, SimulationExtraSettings } from './simulation.types';
import {
  SimulationMenuWizardStepId,
  SimulationWizardConfig,
  simulationWizardStepsCommonDefault,
  simulationWizardStepsOptimizeDefault,
} from './simulation.wizard';
import {
  WorkforceScheduleMap,
  WorkforceSpecialDrillKey,
} from './workforce.types';

const categoryABCColor = TwTheme.extend.colors.categoryABC;
const categoryABCTextColor = TwTheme.extend.colors.categoryABCText;
const eventsColor = TwTheme.extend.colors.events;
const eventsColorAlt = TwTheme.extend.colors.eventsAlt;
const eventGroupsColor = TwTheme.extend.colors.eventGroups;
const eventGroupsColorTxt = TwTheme.extend.colors.eventGroupsTxt;

export function getABCGroup(percentile: number) {
  if (percentile <= 0.1) return 'A';
  if (percentile > 0.8) return 'C';
  return 'B';
}

export function getABCDGroup(cumulativePercentile: number) {
  if (_.isNil(cumulativePercentile)) return 'D';
  if (cumulativePercentile <= 0.5) return 'A+';
  if (cumulativePercentile <= 0.8) return 'A';
  if (cumulativePercentile <= 0.95) return 'B';
  return 'C';
}

export function getABCColor(percentile: number): [string, string] {
  if (percentile <= 0.1) return [categoryABCColor.A, categoryABCTextColor.A];
  if (percentile > 0.8) return [categoryABCColor.C, categoryABCTextColor.C];
  return [categoryABCColor.B, categoryABCTextColor.B];
}

function getEnabledSteps(
  sim: SimulationMetaFragment,
  hasAnalyse: boolean,
  hasOptimise: boolean,
): SimulationMenuWizardStepId[] {
  const enabled: SimulationMenuWizardStepId[] = ['getting-started'];

  if (!sim || sim.id === 'new') {
    return enabled;
  } else {
    enabled.push('import-layout');
  }
  if (sim.layout?.id) {
    enabled.push('import-assignments');
  }
  if (sim.assignment?.id) {
    enabled.push('import-items');
  }
  if (sim.itemSet?.id) {
    enabled.push('import-orders');
  }
  if (sim.orderSet?.id) {
    enabled.push(
      'policy-resources',
      'policy-routing',
      'data-issues',
      'data-stats',
    );
  }

  if (!_.isEmpty(sim.resourcePolicy?.agents)) {
    enabled.push(
      'policy-routing',
      'policy-stacking',
      'policy-storage',
      // 'policy-locations-sharing',
      'policy-picking',
      'allocation-requirement',
      'analyse',
    );
  }

  if (hasAnalyse || hasOptimise) {
    enabled.push('compliance', 'optimise');
  }

  if (hasOptimise) {
    enabled.push('optimise-implement', 'optimise-reassign');
  }
  return enabled;
}

export function getWizardSteps(
  sim: SimulationMetaFragment,
  hasAnalyse: boolean,
  optimiseStatus: OptimizationRunStatus,
  allowOptimise: boolean,
  t: TFunction<'simulation'>,
): SimulationWizardConfig {
  if (_.isNil(sim)) {
    return simulationWizardStepsCommonDefault(t);
  }
  const hasOptimise = optimiseStatus === OptimizationRunStatus.COMPLETED;

  const isOptimising =
    optimiseStatus === OptimizationRunStatus.CREATED ||
    optimiseStatus === OptimizationRunStatus.IN_PROGRESS ||
    optimiseStatus === OptimizationRunStatus.STOPPING;

  const defaultSteps =
    hasOptimise || allowOptimise
      ? simulationWizardStepsOptimizeDefault
      : simulationWizardStepsCommonDefault;

  const enabled = new Set(getEnabledSteps(sim, hasAnalyse, hasOptimise));
  return defaultSteps(t).map((step, i) => ({
    ...step,
    disabled: !enabled.has(step.id),
    progress: step.id === 'optimise' && isOptimising,
  }));
}

export function getEventRoutePart(
  picklistId: string,
  event: AnalyzeEventFragment,
  isFirst = true,
): WaypointLabeled[] {
  const wps: WaypointLabeled[] = [];
  if (isFirst) {
    wps.push({
      id: `${picklistId}-${event.sequenceId}-start`,
      type: WaypointType.INTERNAL,
      position: event.startPosition,
    });
  }

  if (
    // event.details?.__typename === 'TravellingHorizontalDetails' &&
    !_.isEmpty((event.details as TravellingHorizontalDetailsFragment).route)
  ) {
    wps.push(
      ..._.map(
        (event.details as TravellingHorizontalDetailsFragment).route,
        routePoint => ({
          id: `${picklistId}-${event.sequenceId}-${routePoint.id}`,
          type: routePoint.type,
          position: routePoint.position,
        }),
      ),
    );
  }
  if (event.endPosition) {
    wps.push({
      id: `${picklistId}-${event.sequenceId}-end`,
      type: WaypointType.LOCATION,
      position: event.endPosition,
      label: _.isNil(event.picklistLine)
        ? null
        : (event.picklistLine + 1).toString(),
    });
  }
  return wps;
}

export function createRouteForPicklist(
  picklist: AnalyzePicklistEventsFragment,
): PicklistRoute {
  return {
    id: `picklist-route-${picklist.picklist.id}`,
    picklistId: picklist.picklist.id,
    areaId: picklist.picklist.planeId,
    waypoints: picklist.events
      .filter(
        event =>
          event?.details?.__typename === 'TravellingHorizontalDetails' &&
          event?.details?.distance > 0,
      )
      .map((e, i) => getEventRoutePart(picklist.picklist.id, e, i === 0))
      .flat(),
  };
}
export function createRouteForEvent(
  picklist: AnalyzePicklistFragment,
  event: AnalyzeEventFragment,
): PicklistRoute {
  return {
    id: `picklist-part-route-${picklist.picklist.id}-${event.sequenceId}`,
    picklistId: picklist.picklist.id,
    areaId: picklist.picklist.planeId,
    waypoints: getEventRoutePart('', event, true),
    eventType: event.eventType,
  };
}

export type EventGroupGlobal =
  | 'travellingGlobal'
  | 'handlingGlobal'
  | 'ignored';

export const eventGroupGlobalOrder: EventGroupGlobal[] = [
  'travellingGlobal',
  'handlingGlobal',
];

export const EventGroupKeys = [
  'travellingEndpoint',
  'travellingHorizontal',
  'travellingVertical',
  'handling',
  'picking',
  'reordering',
  'ignored',
] as const;

export type EventGroup = (typeof EventGroupKeys)[number];

export const EventGroupGlobalMap: Record<PicklistEventType, EventGroupGlobal> =
  {
    [PicklistEventType.PRE_HANDLING]: 'handlingGlobal',
    [PicklistEventType.HANDLING_PREPARATION]: 'handlingGlobal',
    [PicklistEventType.HANDLING_ON_COMPLETION]: 'handlingGlobal',
    [PicklistEventType.POST_HANDLING]: 'handlingGlobal',
    [PicklistEventType.HANDLING_PALLET_REORDERING]: 'handlingGlobal',
    [PicklistEventType.HANDLING_EXECUTION]: 'handlingGlobal',
    [PicklistEventType.TRAVELLING_HORIZONTAL]: 'travellingGlobal',
    [PicklistEventType.TRAVELLING_VERTICAL]: 'travellingGlobal',
    [PicklistEventType.TRAVELLING_HORIZONTAL_START]: 'travellingGlobal',
    [PicklistEventType.TRAVELLING_HORIZONTAL_END]: 'travellingGlobal',
    [PicklistEventType.SOURCE_EVENT_IGNORED]: 'ignored',
  };

export const EventGroupMap: Record<PicklistEventType, EventGroup> = {
  [PicklistEventType.PRE_HANDLING]: 'handling',
  [PicklistEventType.HANDLING_PREPARATION]: 'handling',
  [PicklistEventType.HANDLING_ON_COMPLETION]: 'handling',
  [PicklistEventType.POST_HANDLING]: 'handling',
  [PicklistEventType.HANDLING_PALLET_REORDERING]: 'reordering',
  [PicklistEventType.HANDLING_EXECUTION]: 'picking',
  [PicklistEventType.TRAVELLING_HORIZONTAL]: 'travellingHorizontal',
  [PicklistEventType.TRAVELLING_VERTICAL]: 'travellingVertical',
  [PicklistEventType.TRAVELLING_HORIZONTAL_START]: 'travellingEndpoint',
  [PicklistEventType.TRAVELLING_HORIZONTAL_END]: 'travellingEndpoint',
  [PicklistEventType.SOURCE_EVENT_IGNORED]: 'ignored',
};

export function getEventGlobalGroups(t: TFunction<'simulation'>) {
  return [
    {
      title: t('Handling', { ns: 'simulation' }),
      eventTypes: [
        PicklistEventType.PRE_HANDLING,
        PicklistEventType.HANDLING_PREPARATION,
        PicklistEventType.HANDLING_ON_COMPLETION,
        PicklistEventType.POST_HANDLING,
        PicklistEventType.HANDLING_PALLET_REORDERING,
        PicklistEventType.HANDLING_EXECUTION,
      ],
    },
    {
      title: t('Travelling', { ns: 'simulation' }),
      eventTypes: [
        PicklistEventType.TRAVELLING_HORIZONTAL,
        PicklistEventType.TRAVELLING_VERTICAL,
      ],
    },
  ];
}
// TODO: AIIAK: Obsolete ???
// export function getEventGroups(t: TFunction<'simulation'>) {
//   return [
//     {
//       title: t('Handling', { ns: 'simulation' }),
//       eventTypes: [
//         PicklistEventType.PRE_HANDLING,
//         PicklistEventType.HANDLING_PREPARATION,
//         PicklistEventType.HANDLING_ON_COMPLETION,
//         PicklistEventType.POST_HANDLING,
//       ],
//     },
//     {
//       title: t('Travelling', { ns: 'simulation' }),
//       eventTypes: [
//         PicklistEventType.TRAVELLING_HORIZONTAL,
//         PicklistEventType.TRAVELLING_VERTICAL,
//       ],
//     },
//     {
//       title: t('Picking', { ns: 'simulation' }),
//       eventTypes: [PicklistEventType.HANDLING_EXECUTION],
//     },
//     {
//       title: t('Reordering', { ns: 'simulation' }),
//       eventTypes: [PicklistEventType.HANDLING_PALLET_REORDERING],
//     },
//   ];
// }

export function getEventTitle(
  eventType: PicklistEventType,
  t: TFunction<'simulation'>,
) {
  switch (eventType) {
    case PicklistEventType.PRE_HANDLING:
      return t('Pre-handling', { ns: 'simulation' });
    case PicklistEventType.TRAVELLING_HORIZONTAL:
      return t('Travelling', { ns: 'simulation' });
    case PicklistEventType.TRAVELLING_HORIZONTAL_START:
      return t('Travelling from start', { ns: 'simulation' });
    case PicklistEventType.TRAVELLING_HORIZONTAL_END:
      return t('Travelling to end', { ns: 'simulation' });
    case PicklistEventType.TRAVELLING_VERTICAL:
      return t('Lifting/Lowering', { ns: 'simulation' });
    case PicklistEventType.HANDLING_PREPARATION:
      return t('Handling preparation', { ns: 'simulation' });
    case PicklistEventType.HANDLING_EXECUTION:
      return t('Picking', { ns: 'simulation' });
    case PicklistEventType.HANDLING_PALLET_REORDERING:
      return t('Pallet Sorting', { ns: 'simulation' });
    case PicklistEventType.HANDLING_ON_COMPLETION:
      return t('Handling Completion', { ns: 'simulation' });
    case PicklistEventType.POST_HANDLING:
      return t('Post-handling', { ns: 'simulation' });
  }
}

export function getEventColor(
  eventType: PicklistEventType,
  isAlternative = false,
) {
  const colorSet = isAlternative ? eventsColorAlt : eventsColor;
  switch (eventType) {
    case PicklistEventType.PRE_HANDLING:
      return colorSet.preHandling;
    case PicklistEventType.TRAVELLING_HORIZONTAL:
      return colorSet.travellingHorizontal;
    case PicklistEventType.TRAVELLING_HORIZONTAL_START:
      return colorSet.travellingHorizontal;
    case PicklistEventType.TRAVELLING_HORIZONTAL_END:
      return colorSet.travellingHorizontal;
    case PicklistEventType.TRAVELLING_VERTICAL:
      return colorSet.travellingVertical;
    case PicklistEventType.HANDLING_PREPARATION:
      return colorSet.handlingPreparation;
    case PicklistEventType.HANDLING_EXECUTION:
      return colorSet.handlingExecution;
    case PicklistEventType.HANDLING_PALLET_REORDERING:
      return colorSet.handlingReordering;
    case PicklistEventType.HANDLING_ON_COMPLETION:
      return colorSet.handlingCompletion;
    case PicklistEventType.POST_HANDLING:
      return colorSet.postHandling;
  }
}

export function getEventGroupColor(
  eventGroup: EventGroup | WorkforceSpecialDrillKey,
) {
  switch (eventGroup) {
    case 'handling':
      return eventGroupsColor.handling;
    case 'picking':
      return eventGroupsColor.picking;
    case 'reordering':
      return eventGroupsColor.reordering;
    case 'travellingHorizontal':
      return eventGroupsColor.travellingHorizontal;
    case 'travellingVertical':
      return eventGroupsColor.travellingVertical;
    case 'travellingEndpoint':
      return eventGroupsColor.travellingEndpoint;
    case '___idle':
      return eventGroupsColor.idle;
  }
}

export function getEventGroupColorText(
  eventGroup: EventGroup | WorkforceSpecialDrillKey,
) {
  switch (eventGroup) {
    case 'handling':
      return eventGroupsColorTxt.handling;
    case 'picking':
      return eventGroupsColorTxt.picking;
    case 'reordering':
      return eventGroupsColorTxt.reordering;
    case 'travellingHorizontal':
      return eventGroupsColorTxt.travellingHorizontal;
    case 'travellingVertical':
      return eventGroupsColorTxt.travellingVertical;
    case 'travellingEndpoint':
      return eventGroupsColorTxt.travellingEndpoint;
    case '___idle':
      return eventGroupsColorTxt.idle;
  }
}

export function getEventGroupTitle(
  eventGroup: EventGroup,
  t: TFunction<'simulation'>,
) {
  switch (eventGroup) {
    case 'picking':
      return t('Picking (Handling)', { ns: 'simulation' });
    case 'handling':
      return t('Prep & Staging', { ns: 'simulation' });
    case 'reordering':
      return t('Reordering', { ns: 'simulation' });
    case 'travellingHorizontal':
      return t('Travelling', { ns: 'simulation' });
    case 'travellingVertical':
      return t('Lifting/Lowering', { ns: 'simulation' });
    case 'travellingEndpoint':
      return t('Travelling Start/End', { ns: 'simulation' });
    // case 'idle':
    //   return t('Idle', { ns: 'simulation' });
  }
}
export function getEventGroupGlobalTitle(
  eventGroup: EventGroupGlobal,
  t: TFunction<'simulation'>,
) {
  switch (eventGroup) {
    case 'travellingGlobal':
      return t('Travelling', { ns: 'simulation' });
    case 'handlingGlobal':
      return t('Handling & Picking', { ns: 'simulation' });
  }
}

export type KPI = 'distance' | 'cost' | 'duration' | 'co2';
export function getKPITitles(kpi: KPI, t: TFunction<'simulation'>): string {
  switch (kpi) {
    case 'distance':
      return t('Distance', { ns: 'simulation' });
    case 'cost':
      return t('Cost', { ns: 'simulation' });
    case 'duration':
      return t('Duration', { ns: 'simulation' });
    case 'co2':
      return t('CO2', { ns: 'simulation' });
  }
}

export function getFormattedKPIValue(
  order: AnalyzeResultOrderSummaryFragment,
  kpi: KPI,
  formatter: Formatter,
): FormattedValueWithUnit {
  if (kpi === 'distance') {
    return formatter.formatDistance(order.totalDistance);
  }
  if (kpi === 'cost') {
    return formatter.formatCurrency(order.totalCost);
  }
  if (kpi === 'duration') {
    if (order.totalDuration >= 24 * 3600)
      return formatter.formatTimeSpan(order.totalDuration, 1);

    return {
      raw: order.totalDuration,
      unitCode: 'ms',
      value: new Date(order.totalDuration * 1000).toISOString().substr(11, 8),
      unit: '',
      prefixUnit: false,
      fullString: new Date(order.totalDuration * 1000)
        .toISOString()
        .substr(11, 8),
    };
  }
}

const kpiToOrderSortMap: Record<KPI, AnalyzeResultOrdersSortOption> = {
  cost: AnalyzeResultOrdersSortOption.TOTAL_COST,
  distance: AnalyzeResultOrdersSortOption.TOTAL_DISTANCE,
  duration: AnalyzeResultOrdersSortOption.TOTAL_DURATION,
  co2: AnalyzeResultOrdersSortOption.TOTAL_DURATION,
};

export function getOrderSortParam(
  kpi: KPI = 'cost',
): AnalyzeResultOrdersSortBy {
  return {
    field: kpiToOrderSortMap[kpi],
    direction: SortDirection.DESC,
  };
}

export function getOrderFilterParam(
  searchValues: OrderSearchValue,
): AnalyzeResultOrdersFilter {
  return {
    orderIdContains: searchValues?.['orderId'],
    pickedProductSkuContains: searchValues?.['sku'],
  };
}

export type LoadLocationHeatmapParams = {
  analyzeId: string;
  areaId: string;
  level?: number;
  bayId?: string;
};
export async function loadLocationHeatmap({
  analyzeId,
  areaId,
  level,
  bayId,
}: LoadLocationHeatmapParams) {
  if (!analyzeId || !areaId) return null;

  const response = await secureClient.query<
    LoadAnalyzeLocationsQuery,
    LoadAnalyzeLocationsQueryVariables
  >({
    query: LoadAnalyzeLocationsDocument,
    variables: {
      analyzeId,
      filter: {
        planeId: [areaId],
        locationBayId: _.isNil(bayId) ? null : [bayId],
        locationLevel: _.isNil(level) ? null : [level],
      },
      page: null,
    },
  });
  const locationMap = _.keyBy(
    response.data?.analyzeResult?.visitedLocations?.content,
    loc => loc.locationId,
  );
  return locationMap;
}

export type LoadWaypointHeatmapParams = {
  analyzeId: string;
  areaId: string;
};
export async function loadWaypointHeatmap({
  analyzeId,
  areaId,
}: LoadLocationHeatmapParams): Promise<AnalyzeResultWaypointFragment[]> {
  if (!analyzeId || !areaId) return null;

  const response = await secureClient.query<
    LoadAnalyzeWaypointsQuery,
    LoadAnalyzeWaypointsQueryVariables
  >({
    query: LoadAnalyzeWaypointsDocument,
    variables: {
      analyzeId,
      planeId: areaId,
    },
  });
  return response.data?.analyzeResult?.waypoints?.content;
}

export type AnalyzeResultType = 'before' | 'after';
export function getAnalyzeResultTypeTitle(
  type: AnalyzeResultType,
  t: TFunction<'simulation'>,
): string {
  switch (type) {
    case 'before':
      return t(`Before`, { ns: 'simulation' });
    case 'after':
      return t(`After`, { ns: 'simulation' });
  }
}

export function getSimulationExtraSettingsInput(params: {
  current: SimulationExtraSettings;
  workforceSchedules: WorkforceScheduleMap;
}): SimulationExtraSettings {
  let settings: SimulationExtraSettings = cloneWithoutTypename(params.current);
  if (!_.isNil(params.workforceSchedules)) {
    settings = {
      ...settings,
      workforceSettings: {
        schedules: params.workforceSchedules,
        version: WORKFORCE_FORECAST_VERSION,
      },
    };
  }
  return settings;
}

export function getMoveTypeDescriptors(t: TFunction<'simulation'>): {
  id: AssignmentChangeSequenceAction;
  title: string;
  icon?: React.FC<HTMLAttributes<Element>>;
}[] {
  return [
    {
      id: AssignmentChangeSequenceAction.TAKE,
      title: t(`Take out`, { ns: 'simulation' }),
      icon: Icon.MoveTypeMoveTake,
    },
    {
      id: AssignmentChangeSequenceAction.PUT,
      title: t(`Put in`, { ns: 'simulation' }),
      icon: Icon.MoveTypeMovePut,
    },
    {
      id: AssignmentChangeSequenceAction.EXCHANGE,
      title: t(`Swap (exchange)`, { ns: 'simulation' }),
      icon: Icon.MoveTypeSwapArrows,
    },
    {
      id: AssignmentChangeSequenceAction.STASH,
      title: t(`Stage (Transient)`, { ns: 'simulation' }),
      icon: Icon.MoveTypeMoveStash,
    },
    {
      id: AssignmentChangeSequenceAction.UNSTASH,
      title: t(`Unstage`, { ns: 'simulation' }),
      icon: Icon.MoveTypeMoveUnstash,
    },
  ];
}
