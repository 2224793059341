import _ from 'lodash';
import React, { Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { AsyncLoadStatus } from '../common/types';
import { cn } from '../common/utils';
import ErrorIndicator from '../components/ErrorIndicator';
import LoadingIndicator from '../components/LoadingIndicator';
import ButtonSwitchMulti from '../components/actions/ButtonSwitchMulti';
import { PanelHeader } from '../components/designer/panels/PanelHeader';
import { HeaderAppMenu } from '../components/headers/HeaderAppMenu';
import { Container } from '../components/layout/ContainerFlex';
import { ContainerMain } from '../components/layout/ContainerMain';
import { Spacer } from '../components/layout/Spacer';
import DatasetViewer from '../datasetObject/DatasetViewer';
import FeedDatePicker from '../feed/components/FeedDatePicker';
import FeedCommonInfoSidebar from '../feed/sidebar/FeedCommonInfoSidebar';
import LayoutActiveFeatureTag from '../layout/LayoutActiveFeatureTag';
import { useLoadLayout } from '../layout/hooks/useLoadLayout';
import { layoutDocumentLoadStatus } from '../layout/store/layout.state';
import useUserPermissionFeatures from '../permissions/roles/hooks/useUserPermissionFeatures';
import { resourcePolicySelectedAgentId } from '../simulation/store/resourcePolicy.state';
import { appIsEmbedded } from '../store/global.state';
import { sidebarStateByType } from '../store/sidebar.state';
import FeedHeader from './FeedHeader';
import FeedActiveFiltersTags from './components/FeedActiveFiltersTags';
import FeedLayoutViewSwitch from './components/FeedLayoutViewSwitch';
import ActualityHeatmapDataTable from './heatmapTable/ActualityHeatmapDataTable';
import useActualityTimeWatcher from './hooks/useActualityTimeWatcher';
import { useLoadActuality } from './hooks/useLoadActuality';
import ActualityHqDataTable from './hqTable/ActualityHqDataTable';
import ActualityJobsTable from './jobsTable/ActualityJobsTable';
import FeedActivitySwarmRoot from './layout/FeedActivitySwarmRoot';
import FeedPolicyAgentViewContainer from './layout/FeedPolicyAgentViewContainer';
import FeedSetup from './layout/FeedSetup';
import ViewerActivityFeed from './layout/ViewerActivityFeed';
import ViewerJobsTimeline from './layout/ViewerJobsTimeline';
import ActualityHeatmapBucketsSidebar from './sidebar/ActualityHeatmapBucketsSidebar';
import FeedEventsSidebar from './sidebar/FeedEventsSidebar';
import FeedLocationSidebar from './sidebar/FeedLocationSidebar';
import FeedMainSidebar from './sidebar/FeedMainSidebar';
import FeedMenuSidebar from './sidebar/FeedMenuSidebar';
import {
  actualityMainViewType,
  actualitySelectedDocument,
  actualitySelectedDocumentStatus,
} from './store/actuality.state';
import {
  activityFeedSidebarMenuState,
  feedActivitySelectedTabId,
  feedLayoutId,
  feedSelectedAgentId,
  feedSelectedDateRange,
  feedSelectedMenuItemId,
} from './store/feed.state';

type FeedRootParams = {
  actualityId: string;
  isEmbedded: string;
};

export const FeedRoot: React.FC = () => {
  const { t } = useTranslation('feed');
  const { actualityId, isEmbedded: isEmbeddedParam } =
    useParams<FeedRootParams>();
  const actuality = useRecoilValue(actualitySelectedDocument);
  const actualityLoadStatus = useRecoilValue(actualitySelectedDocumentStatus);
  const resetSelectedDateRange = useResetRecoilState(feedSelectedDateRange);

  const layoutId = useRecoilValue(feedLayoutId);
  const layoutLoadStatus = useRecoilValue(layoutDocumentLoadStatus);

  const [activeMenuId, setActiveMenuId] = useRecoilState(
    feedSelectedMenuItemId(actualityId),
  );
  const activityTabId = useRecoilValue(feedActivitySelectedTabId);
  const [selectedAgentId, setSelectedAgentId] =
    useRecoilState(feedSelectedAgentId);
  const [loadLayout, cleanupLayout] = useLoadLayout();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadActuality, unloadActuality] = useLoadActuality();

  useActualityTimeWatcher();
  const isEmbeddedAtom = useRecoilValue(appIsEmbedded);

  const hasHeader =
    _.isNil(searchParams.get('token')) &&
    _.isNil(isEmbeddedParam) &&
    !isEmbeddedAtom;
  const rpSelectedAgentId = useRecoilValue(resourcePolicySelectedAgentId);
  const setSidebarState = useSetRecoilState(
    sidebarStateByType('sidebar-feed-info-event'),
  );
  const mainViewType = useRecoilValue(actualityMainViewType);

  const [showActivityFeedSidebarMenu, setShowMenuSidebarFeed] = useRecoilState(
    activityFeedSidebarMenuState,
  );

  const visibleFeatures = useUserPermissionFeatures();
  const isSidebarFeedMenuVisible = visibleFeatures.find(
    f => f.id === 'sidebar-feed-menu',
  )?.visible;

  const isSidebarFeedMainVisible = visibleFeatures.find(
    f => f.id === 'sidebar-feed-main',
  )?.visible;

  const [activeTableTab, setActiveTableTab] = useState('hq');

  const displayOptions = [
    { value: 'hq', label: 'Feed Data' },
    { value: 'jobs', label: 'Events Analysis' },
    { value: 'heatmap', label: 'Location Analysis' },
  ];

  useEffect(() => {
    if (!_.isNil(actualityId)) {
      resetSelectedDateRange();
      loadActuality(actualityId);
    }
    return () => {
      unloadActuality();
    };
  }, [actualityId]);

  useEffect(() => {
    if (!_.isNil(layoutId)) {
      loadLayout(layoutId);
    }
    return () => {
      cleanupLayout();
    };
  }, [layoutId]);

  useEffect(() => {
    setSidebarState({
      isHidden: !selectedAgentId,
      isCollapsed: !selectedAgentId,
    });
  }, [selectedAgentId]);

  const isReady = actualityLoadStatus === AsyncLoadStatus.Ok;
  // &&
  //layoutLoadStatus === AsyncLoadStatus.Ok;

  const showLocationViews =
    activeMenuId === 'feed-menu-setup' ||
    (activeMenuId === 'feed-menu-activity' && activityTabId !== 'tab-feed-kpi');

  const showKPIView =
    activeMenuId === 'feed-menu-activity' && activityTabId === 'tab-feed-kpi';

  const showEventsSidebar =
    activeMenuId === 'feed-menu-activity' &&
    activityTabId === 'tab-feed-process';

  const showHeatmapLegendSidebar =
    activeMenuId === 'feed-menu-activity' &&
    activityTabId === 'tab-feed-heatmaps';

  function getContent() {
    if (activeMenuId === 'feed-menu-setup' && !_.isNil(rpSelectedAgentId)) {
      return (
        <>
          <HeaderAppMenu hasControls={false} title={t`Agent details`} />
          <FeedPolicyAgentViewContainer />
        </>
      );
    }
    if (_.isNil(actuality.layoutId) || _.isNil(actuality.activityFeedId)) {
      return <FeedSetup />;
    }

    if (showKPIView) {
      return <FeedActivitySwarmRoot />;
    }

    return (
      <>
        <Container
          col
          overflow
          flex1
          componentName={`ActualityViewContainer`}
          className={cn('border-app-panel-dark', 'ltr:border-l rtl:border-r')}
        >
          <ViewerActivityFeed />
          {/* {mainViewType === 'layout' && <ViewerActivityFeed />} */}
        </Container>

        {mainViewType === 'table' && (
          <DatasetViewer
            mode="actuality"
            classNameHeader="py-0.5"
            headerAction={
              <ButtonSwitchMulti
                autoSize
                className="mx-2"
                classNameLabel="text-xs py-0.5 px-2"
                buttonType="minimal"
                selectedIndex={displayOptions.findIndex(
                  option => option.value === activeTableTab,
                )}
                onClick={index =>
                  setActiveTableTab(displayOptions[index].value)
                }
                options={displayOptions}
              />
            }
          >
            {activeTableTab === 'hq' && (
              <ActualityHqDataTable id="actuality-hq-table" />
            )}
            {activeTableTab === 'jobs' && (
              <ActualityJobsTable id="actuality-job-table" />
            )}
            {activeTableTab === 'heatmap' && (
              <ActualityHeatmapDataTable id="actuality-heatmap-table" />
            )}
          </DatasetViewer>
        )}
      </>
    );
  }

  if (!isReady) {
    return (
      <>
        {hasHeader && <FeedHeader />}
        <ContainerMain data-component="activity-feed-container">
          <LoadingIndicator selfCenter message={t`Loading Data...`} />
        </ContainerMain>
      </>
    );
  }
  return (
    <>
      {hasHeader && <FeedHeader />}

      <ContainerMain data-component="activity-feed-container">
        <div
          data-component="sidebar-left-container"
          className={cn('sidebar-wrapper side-left flex')}
        >
          {isSidebarFeedMenuVisible && showActivityFeedSidebarMenu && (
            <FeedMenuSidebar />
          )}
          {isSidebarFeedMainVisible && <FeedMainSidebar />}

          {showEventsSidebar && <FeedEventsSidebar />}
          {showHeatmapLegendSidebar && <ActualityHeatmapBucketsSidebar />}
        </div>
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <ErrorIndicator
              selfCenter
              message={error.message}
              hasReloadButton
              exception={error}
            />
          )}
        >
          <Suspense
            fallback={
              <Container hasOverflowY col>
                <HeaderAppMenu
                  isLoading
                  title={showKPIView ? t`Loading...` : t`Loading Feed...`}
                />
                <LoadingIndicator
                  className={cn(
                    'bg-map-area-striped',
                    'border-app-background border-8',
                  )}
                  selfCenter
                  message={
                    showKPIView ? t`Loading Data Chart` : t`Loading Feed...`
                  }
                />
              </Container>
            }
          >
            <Container col componentName="FeedActivityRoot">
              <PanelHeader>
                <FeedDatePicker />
                <Spacer flexspace />
                {!showKPIView && (
                  <>
                    <LayoutActiveFeatureTag />
                    <FeedActiveFiltersTags />
                  </>
                )}
                <FeedLayoutViewSwitch />
              </PanelHeader>
              <Suspense
                fallback={
                  <LoadingIndicator
                    className={cn(
                      'bg-map-area-striped',
                      'border-app-background border-8',
                    )}
                    selfCenter
                    message={
                      showKPIView ? t`Loading Data Chart` : t`Loading Feed...`
                    }
                  />
                }
              >
                {mainViewType === 'timeline' && <ViewerJobsTimeline />}

                {getContent()}

                {/* <DatasetViewer mode="actuality"></DatasetViewer> */}
              </Suspense>
            </Container>
          </Suspense>
        </ErrorBoundary>
        <div
          data-component="sidebar-right-container"
          className={cn('sidebar-wrapper side-right flex')}
        >
          {showLocationViews && (
            <>
              <FeedLocationSidebar />
              <FeedCommonInfoSidebar />
            </>
          )}
        </div>
      </ContainerMain>
    </>
  );
};

export default FeedRoot;
