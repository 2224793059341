import {
  ExportJobFragment,
  SortDirection,
} from '@warebee/frontend/data-access-api-graphql';
import { ColumnConfigBase } from '@warebee/shared/export-converter';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableVirtuoso, TableVirtuosoProps } from 'react-virtuoso';
import { cn } from '../common/utils';
import { Table } from '../components/table/Table';
import { TableTd } from '../components/table/TableTd';
import DatasetTableHeader from './DatasetTableHeader';
import InboxZero from './InboxZero';
import LoadingIndicator from './LoadingIndicator';
import { ButtonLoadMore } from './actions/ButtonLoadMore';
import ExportButton from './actions/ExportButton';
import InputCheckbox from './inputs/InputCheckbox';
import { ScreenTitle } from './layout/ScreenTitle';
import { Spacer } from './layout/Spacer';
import { TableBody } from './table/TableBody';
import { TableRow } from './table/TableRow';
import { TableRowBody } from './table/TableRowBody';

export type ColumnConfig<T extends Object> = ColumnConfigBase<T> & {
  unitOfMeasure?: string;
  hasFilter?: boolean;
  hasSort?: boolean;
  isHeader?: boolean;
  filterOptions?: Record<string, string>;
  hasTagClass?: string;
  render?: (value: T[keyof T], row: T, isSelected?: boolean) => React.ReactNode;
};

export type DatasetTableProps<T extends Object> = {
  id: string;
  columnsConfig: ColumnConfig<T>[];
  keyFields: (keyof T)[];
  searchValues: Partial<Record<keyof T, string>>;
  checkedValues?: T[];
  sortBy?: Partial<Record<keyof T, SortDirection>>;
  data: T[];
  totalCount: number;
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  isSticky?: boolean;
  hideScreenTitle?: boolean;
  hasSortByMultipleColumns?: boolean;
  isActionable?: boolean;
  hasCounter?: boolean;
  hasExport?: boolean;
  hasRowCounter?: boolean;
  hasCheckboxes?: boolean;
  hasRowError?: boolean;
  children?: React.ReactNode;
  childrenHeader?: React.ReactNode;
  actionSection?: React.ReactNode;
  actionBar?: React.ReactNode;
  childrenNoItems?: React.ReactNode;
  classNameHeader?: string;
  className?: string;
  onRowSelect?: (row: T, index: number) => void;
  onRowCheck?: (row: T, index: number, isSelected: boolean) => void;
  onSearch: (searchValues: Partial<Record<keyof T, string>>) => void;
  onSort?: (sortValues: Partial<Record<keyof T, SortDirection>>) => void;
  onLoadNext: () => void;
  onStartExportClick?: () => Promise<{ job: ExportJobFragment; errors: any }>;
  onStartExtraExportClick?: () => Promise<{
    job: ExportJobFragment;
    errors: any;
  }>;
  selectedRow?: T;
};

const DatasetTable = <DataRow extends Object>(
  props: DatasetTableProps<DataRow>,
) => {
  const { t } = useTranslation('dataset');

  const [selectedRow, setSelectedRow] = useState<DataRow>();
  const [selectedExportAction, setSelectedExportAction] = useState<string>();

  useEffect(() => {
    setSelectedRow(props.selectedRow);
  }, [props.selectedRow]);

  useEffect(() => {
    if (selectedExportAction) {
      handleExportAction(selectedExportAction);
      setSelectedExportAction(''); // Reset selection after handling action
    }
  }, [selectedExportAction]);

  function onRowSelect(row: DataRow, index: number) {
    props.onRowSelect && props.onRowSelect(row, index);
    setSelectedRow(row);
  }

  function getRowKey(row: DataRow): string {
    return props.keyFields.map(f => row[f]).join('—');
  }
  const visibleColumns = props.columnsConfig.filter(
    col => !col.isHidden && !col.hiddenInBrowser,
  );
  const columnsCount = _.size(visibleColumns);
  const isLoading = props.isLoading;
  const itemsCount = props.data?.length || 0;
  const totalCount = props.totalCount;
  const canLoadMore = totalCount > 0 && itemsCount < totalCount;

  const exportActions = ['Start Export', 'Start Extra Export'];
  const exportActionMap: Record<string, () => Promise<any>> = {
    'Start Export': props.onStartExportClick,
    'Start Extra Export': props.onStartExtraExportClick,
  };

  const handleExportAction = async (action: string) => {
    const exportFunction = exportActionMap[action];
    if (exportFunction) {
      await exportFunction();
    }
  };

  const checkedKeys = new Set<string>(_.map(props.checkedValues, getRowKey));
  const tableVirtuosoProps: TableVirtuosoProps<DataRow, any> = {
    className: 'w-full',
    data: props.data,
    totalCount: totalCount,
    defaultItemHeight: 24,
    increaseViewportBy: { top: 100, bottom: 100 },
    endReached: () => {
      if (
        _.isFunction(props.onLoadNext) &&
        totalCount > itemsCount &&
        !isLoading
      ) {
        props.onLoadNext();
      }
    },
    fixedFooterContent: () => {
      return (
        <TableTd colSpan={columnsCount}>
          {canLoadMore && (
            <LoadingIndicator
              className="min-h-6"
              oneLiner
              selfCenter
              message={t`Loading Data...`}
            />
          )}
        </TableTd>
      );
    },

    components: {
      Table: ({ children }) => (
        <Table
          dataComponent={'DatasetTable'}
          isHoverable={props.isActionable}
          isSticky
          flexHeight={isLoading || totalCount === 0 || itemsCount >= 20}
          className="text-menu-text"
        >
          <>{children}</>
        </Table>
      ),

      TableBody: React.forwardRef((props, ref) => (
        <tbody className="relative isolate" {...props} ref={ref} />
      )),

      TableRow: rowProps => {
        const row = rowProps.item;
        const index = rowProps['data-item-index'];
        const key = getRowKey(row);
        const isSelected =
          selectedRow && getRowKey(selectedRow) === getRowKey(row);

        return (
          <TableRowBody
            key={`row-${index}-${key}`}
            {...(rowProps as any)}
            isHoverable={props.isActionable}
            isSelected={props.isActionable && isSelected}
            onClick={() =>
              props.isActionable && onRowSelect(isSelected ? null : row, index)
            }
          />
        );
      },

      TableFoot: React.forwardRef((props, ref) => {
        return (
          <tfoot
            ref={ref}
            key={`td-footer-loading-${totalCount}-${canLoadMore}`}
            className={classNames({
              hidden: !canLoadMore,
            })}
          >
            <TableRow {...props}>
              <>{props.children}</>
            </TableRow>
          </tfoot>
        );
      }),

      EmptyPlaceholder: () => {
        return (
          <TableBody>
            <TableRowBody key={`empty-placeholder-${isLoading}-${totalCount}`}>
              <TableTd
                colSpan={columnsCount}
                className={classNames(
                  'sticky bottom-0 z-40 h-full min-h-20 w-full',
                )}
              >
                {isLoading && (
                  <LoadingIndicator
                    className={classNames('min-h-8')}
                    selfCenter
                    absolute
                    oneLiner
                    message={t`Loading Table Data`}
                  />
                )}
                {totalCount === 0 && !isLoading && (
                  <InboxZero selfCenter hasIcon message={t`No Items`}>
                    {props.childrenNoItems}
                  </InboxZero>
                )}
              </TableTd>
            </TableRowBody>
          </TableBody>
        );
      },
    },

    fixedHeaderContent: () => (
      <DatasetTableHeader key={`${props.id}-fixed-header`} {...props} />
    ),

    itemContent: (index, row) => {
      const key = getRowKey(row);
      const isSelected =
        selectedRow && getRowKey(selectedRow) === getRowKey(row);
      return (
        <>
          {props.hasRowCounter && (
            <TableTd
              data-component="DatasetTableRowCounter"
              className={cn(
                'bg-table-row/50',
                'border-app-panel-dark/20 border-r',
                '!px-2',
              )}
            >
              <span className={cn('text-menu-text/50 text-xxs')}>
                {index + 1}
              </span>
            </TableTd>
          )}
          {props.hasCheckboxes && (
            <TableTd
              data-component="DatasetTableRowCounter"
              className={classNames(
                'items-center justify-center',
                'bg-table-row/50',
                'border-app-panel-dark/20 border-r',
                '!p-2',
              )}
            >
              <InputCheckbox
                isSelected={checkedKeys.has(key)}
                onChange={isSelected =>
                  props.onRowCheck(row, index, isSelected)
                }
              />
            </TableTd>
          )}

          {props.columnsConfig
            .filter(col => !col.isHidden && !col.hiddenInBrowser)
            .map((column, columnIndex) => {
              // return (
              //   <td key={`td-${columnIndex}-${column.field as string}`}>
              //     {row[column.field]?.toString() ?? '—'}
              //   </td>
              // );

              return (
                <TableTd
                  key={`td-${columnIndex}-${column.field as string}`}
                  className={classNames('relative', 'select-text', {
                    'cursor-pointer': props.isActionable,
                    'bg-alerts-error/50': props.hasRowError,

                    // 'sticky left-0 isolate z-50 bg-opacity-80 backdrop-blur':
                    //   column.isHeader,

                    'bg-menu-active/20 text-menu-text hover:bg-opacity-30':
                      isSelected,
                    'bg-table-row text-menu-text': !isSelected,
                  })}
                  cellSize={`sm`}
                  alignChildren={'center'}
                >
                  <span
                    className={classNames(
                      'flex flex-wrap items-baseline',
                      'space-y-1 2xl:space-y-0',
                      // {
                      //   'sticky left-0 bg-opacity-80 backdrop-blur backdrop-filter':
                      //     column.isHeader,
                      // },
                    )}
                  >
                    {column.render
                      ? column.render(_.get(row, column.field), row, isSelected)
                      : _.get(row, column.field)?.toString() ?? '—'}
                  </span>
                </TableTd>
              );
            })}
        </>
      );
    },
  };
  return (
    <>
      <header
        data-component="DatasetTableHeader"
        className={classNames(props.classNameHeader)}
      >
        {props.title && !props.hideScreenTitle && (
          <ScreenTitle
            subtitle={props.subtitle}
            title={props.title}
            isSticky={props.isSticky}
          />
        )}

        {itemsCount > 0 && (
          <nav
            className={classNames('group-hover:text-menu-active flex', {
              'py-2': props.hasCounter || !props.hideScreenTitle,
            })}
          >
            {props.hasCounter && (
              <ButtonLoadMore
                itemsCount={itemsCount}
                totalCount={totalCount}
                onNext={props.onLoadNext}
                isLoading={isLoading}
                totalLabel={t`Total:`}
              />
            )}

            {props.actionSection ? props.actionSection : <Spacer flexspace />}

            {/* <DropdownSelector
              className="mx-1 text-sm"
              DropAlignLeft
              panelMode
              value={selectedExportAction}
              values={exportActions}
              onChange={(value: string) => setSelectedExportAction(value)}
              renderValue={(action: string) => t(action)}
            /> */}

            {props.onStartExportClick && (
              <ExportButton onStartExportClick={props.onStartExportClick} />
            )}
            {props.onStartExtraExportClick && (
              <ExportButton
                title={t`Export`}
                onStartExportClick={props.onStartExtraExportClick}
              />
            )}
            {props.childrenHeader}
          </nav>
        )}
        {props.actionBar}
      </header>

      <TableVirtuoso {...tableVirtuosoProps} />

      {props.children}
    </>
  );
};

export default DatasetTable;
